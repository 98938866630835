import React, { useContext, useEffect } from "react";
import DesignHubContext from "../../../components/hooks/DesignHub/createContext";
import { handleImageScale } from "../../../helpers/scaleHelper";
import { DesignImage } from "../../../components/helpers/Interfaces";
import { modelInputProps } from "../../../helpers/Interfaces";
import ImageTool from "./imageTool";

export const ImageLoader = (props: any) => {
    const {
        image: [image, setImage],
        isLoading: [, setIsLoading]
    } = useContext(DesignHubContext)!;

    useEffect(() => {
        if (!props.designImage) { // clear the image from design hub
            setImage(undefined);
            return;
        }
        if (!props.designImage || props.designImage.src === image?.src) return;
        loadImage(props.designImage);
    }, [props.designImage]);

    const loadImage = async (designImage: DesignImage) => {
        setIsLoading(true);
        if (!designImage) {
            setImage(undefined);
            return;
        }
        try {
            const img = new Image();
            img.src = designImage.src;
            img.onload = () => {
                const { height, width } = handleImageScale(img);
                img.width = width;
                img.height = height;
                setImage(img);
                setIsLoading(false);
            };
        } catch (error) {
            console.log(error);
        }
    };



    const getClick = (x: number, y: number): modelInputProps => {
        const clickType = 1;
        return { x, y, clickType };
    };

    return (
        <div className="imageWrapper" id="imageWrapper">
            <ImageTool />
        </div>
    );
}
