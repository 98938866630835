import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import { MdCancel } from "react-icons/md";
import { TbArrowRight } from "react-icons/tb";
import { createWorkspaceFolder } from "../../services/aws/s3/aws_s3_helper";
import { Workspace } from "../../helpers/backend_interfaces";
import { Workspaces } from "./workspaces";
import { initInvokeEndpoint } from "../../services/aws/sagemaker/aws_sagemaker_helper";
import { NotEnougCreditsError, SubscriptionRequiredError } from "../../components/helpers/Interfaces";
import { useNavigate } from "react-router";
import { Hub, I18n } from "aws-amplify";
import StoreFront from "../StoreFront";
import Paywall from "../Subscriptions/Paywall";

const Workspace = (props: any) => {

    const [workspaceCount, setWorkspaceCount] = useState<number>(-1);

    const [newWorkspace,] = useState<Workspace | undefined>(undefined);
    const [isNewWorkspace, setIsNewWorkspae] = useState<boolean>(false);
    // Form
    const [workspaceName, setWorkspaceName] = useState<string>(I18n.get('default_workspace_name'));
    const [workspaceColor, setWorkspaceColor] = useState<string>("#5156be");

    const [isSubscribed, setIsSubscribed] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        Hub.dispatch("app_events", {
            event: "workspaces_loaded"
        });

        // Get user subscription status
        Promise.resolve(initInvokeEndpoint())
            .then((_) => {
                setIsSubscribed(true);
            })
            .catch((error) => {
                if (error instanceof SubscriptionRequiredError) {
                    setIsSubscribed(false);
                } else if (error instanceof NotEnougCreditsError) {
                    setIsSubscribed(true);
                }
            });
    }, []);


    useEffect(() => {
        if (workspaceCount < 0) return;
        if (isSubscribed && workspaceCount === 0) {
            handleNewWorkspace(null);
        }
    }, [workspaceCount])




    function handleNewWorkspace(e: any) {
        Promise.resolve(
            createWorkspaceFolder(workspaceName, workspaceColor))
            .then((workspace) => {
                navigate(`/designhub/${workspace.id}`);
            });
    }

    return (
        <div className="page-content">
            <Container fluid>
                {/* Render Breadcrumb */}
                {!props.showDirectPaywall && <StoreFront />}
                {isSubscribed &&
                    <div>
                        <Breadcrumbs title={I18n.get("home_decor_title")} breadcrumbItem={I18n.get("workspaces")} creditsRemaining={-1} />
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Col xl={3} lg={4}>
                                        <Card>
                                            <CardBody>
                                                <div className="d-grid">
                                                    <Button
                                                        color="primary"
                                                        className="font-16 btn-primary"
                                                        onClick={() => setIsNewWorkspae(true)}
                                                    >
                                                        <Icon name="plus" />
                                                        {I18n.get("create_workspace")}
                                                    </Button>
                                                </div>
                                                {isNewWorkspace && <Form
                                                    className="custom-form mt-4 pt-2"
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        return false;
                                                    }}
                                                >
                                                    <div className="mb-3">
                                                        <Label className="form-label">Workspace Name</Label>
                                                        <Input
                                                            id="workspace-name-input"
                                                            className="form-control"
                                                            name="positivePrompt"
                                                            placeholder="Living Room"
                                                            onChange={(e) => setWorkspaceName(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Label htmlFor="example-color-input" className="form-Label">Workspace Color</Label>
                                                        <Input type="color"
                                                            className="form-control form-control-color mw-100"
                                                            id="workspace-color-input"
                                                            defaultValue={workspaceColor} title={I18n.get("choose_ws_color")}
                                                            onChange={(e) => setWorkspaceColor(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="float-right mt-3 space-x-4">
                                                        <Button color="secondary"
                                                            type="submit"
                                                            onClick={() => setIsNewWorkspae(false)}>
                                                            Cancel <MdCancel />
                                                        </Button>
                                                        <Button color="primary"
                                                            type="submit"
                                                            onClick={handleNewWorkspace}>
                                                            Create
                                                            <TbArrowRight />
                                                        </Button>
                                                    </div>
                                                </Form>}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl={9} lg={8}>
                                        <Workspaces
                                            title={"showing_available_wkspcs"}
                                            setWorkspaceCount={setWorkspaceCount}
                                            newWorkpsace={newWorkspace} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                }
            </Container>
        </div>
    );
}

export default withTranslation()(Workspace);
