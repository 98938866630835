import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import SplashScreen from "../components/SplashScreen";

const OAuthHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                await Auth.currentAuthenticatedUser();
                navigate('/');  // redirect to your app's main page
            } catch (error) {
                navigate('/');  // redirect to login page if the auth check fails
            }
        };

        checkAuth();
    }, [history]);

    return (
        <SplashScreen />
    );
};

export default OAuthHandler;
