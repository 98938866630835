import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem, Badge } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";
import { I18n } from "aws-amplify";
interface BreadcrumbProps {
  breadcrumbItem: string;
  title: string;
  creditsRemaining: number;
  showCredits?: boolean;
  badgeTitle?: string;
  badgeColor?: string;
  children?: any
}
const Breadcrumb = ({ breadcrumbItem, title, badgeColor, badgeTitle, creditsRemaining, showCredits, children }: BreadcrumbProps) => {
  let credisBg = creditsRemaining > 10 ? "bg-success" : "bg-warning";
  credisBg = creditsRemaining === 0 ? "bg-danger" : credisBg;

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="grid gap-4 grid-cols-2">
            <h4 className="mb-0 font-size-18 gap-x-5">
              {breadcrumbItem}
            </h4>
            {badgeColor &&
              <h5>
                <Badge className="rounded-pill bg-primary">
                  {badgeTitle || "Untitled"}
                </Badge>
              </h5>
            }
            {showCredits && creditsRemaining > -1 &&
              <h5>
                <Badge className={`rounded-pill ${credisBg}`}>
                  {`${creditsRemaining} ${I18n.get("credits_remaining")}`}
                </Badge>
              </h5>
            }
            {children}
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0 space-x-2">
              <BreadcrumbItem>
                <Link to="#">{title}</Link>
              </BreadcrumbItem>
              <li><FaChevronRight /></li>
              <BreadcrumbItem active>
                <Link to="#">{breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row >
  );
};

export default Breadcrumb;
