import { Alert, Col, Spinner } from "reactstrap";
import { Workspace } from "../../helpers/backend_interfaces";
import React, { useContext, useEffect } from "react";
import { WorkspaceFolder } from "./workspace_folder";
import { getWorkspaceFolders } from "../../services/aws/s3/aws_s3_helper";
import DesignHubContext from "../../components/hooks/DesignHub/createContext";
import { useNavigate } from "react-router";
import { I18n } from "aws-amplify";

export interface WorkspaceProps {
    title: string
    setWorkspaceCount: Function
    newWorkpsace?: Workspace
}

export const Workspaces = (props: WorkspaceProps) => {
    const navigate = useNavigate();

    const {
        workspaces: [workspaces, setWorkspaces],
        isLoading: [isLoading, setIsLoading],
        currentWorkspace: [currentWorkspace, setCurrentWorkspace]
    } = useContext(DesignHubContext)!;

    useEffect(() => {
        getWorkspaceFolders().then((r) => {
            setWorkspaces(r.slice());
            props.setWorkspaceCount(r.length);
            setIsLoading(false);
        });
    }, [isLoading]);



    useEffect(() => {
        if (!props.newWorkpsace) return;
        setWorkspaces([props.newWorkpsace, ...workspaces]);
        setCurrentWorkspace(props.newWorkpsace);
        navigate("/designhub");
    }, [props.newWorkpsace])

    return (
        <Col xl={9} lg={8}>
            <p className="text-lg">{I18n.get(props.title)}</p>

            {!isLoading && workspaces && workspaces.length == 0 &&
                <Alert
                    color="danger"
                    className="alert-outline">
                    {I18n.get("no_workspace_available")}
                </Alert>
            }

            {
                isLoading &&
                <Spinner className="ms-2 align-center" color="primary" />
            }
            <div className="grid md:grid-cols-4 md:gap-4 sm:grid-cols-2 sm:gap-2">
                {
                    workspaces.map((w: Workspace) => {
                        return (
                            <WorkspaceFolder key={w.id} {...w} />
                        )
                    })
                }
            </div>
        </Col>
    );
}
