import { AppDictionary } from "./app_types";

export const enAppDict = {
    'lang': 'en',
    'what_that_app_can_do': 'Scroll down and check out what the app can do or',
    'click_here_to_get_started': 'Click here to get started!',
    'before': 'Before',
    'after': 'After',
    'title': 'Your Future Home Awaits | HomeDecorAI',
    'error_processing': 'Please refresh the page. There was an error processing your request.',
    'detecting_objects': 'Detecting all objects on the image...',
    'image_ready': 'Image objects processed. Tap or click an object to select, then tap or click on remove to erase.',
    'need_more_credits': 'You have used up all your credits for this month. If your subscription is active, you can reach out to thehomedecorai+support@gmail.com for additional credits',
    'system_taking_too_long': 'Our system is taking longer than usual. Retrying...',
    'finished_object_detection': "Please tap or click on 'process the image objects' to be able to use remove",
    'upload_image': 'Please upload an image',
    'upload_image_or_inspire': 'Please upload an image or use inspire to generate one',
    'use_own_or_inspire': 'Use your own image(s) or use the inspire me functionality to generate one',
    'select_workspace': 'Please select a workspace',
    'upload_image_instructions': 'Drag and drop an image to upload, you can also click or tap to upload',
    'design_hub': 'Design Hub',
    'home_decor_title': 'HomeDecorAI',
    'workspaces': 'My Workspaces',
    'manage_subscription': 'Manage My Subscription',
    'showing_available_wkspcs': 'Show your available workspaces',
    'create_workspace': 'Create New Workspace',
    'Menu': 'Menu',
    'designed_by': 'Designed & Developed by',
    'Logout': 'Logout',
    'paywall_lead': 'Become your own interior designer. Subscribe now for exclusive access to premium design resources and see the magic happen!',
    'switch_workspace': 'Switch workspace',
    'credits_remaining': 'Credits remaining',
    'loading': 'Loading...',
    'no_images_found': 'No images found on workspace',
    'undo': 'Undo',
    'design_for_me_button': 'Design for me',
    'redesign_my_image_button': 'Redesign My Image',
    'deselect': 'Deselect',
    'click_or_tap_to_allow_replace': 'Please tap or click on process image objects to allow replace',
    'click_or_tap_to_allow_remove': "Please tap or click on 'Process image objects' to allow remove",
    'enhance': 'Enhance',
    'process_image_objects': 'Process image objects',
    'delete': 'Delete',
    'replace_mode': 'Replacement Mode:',
    'replace_selected': 'Replace selected',
    'replace_everything_else': 'Replace Everything Else',
    'finish_image_detect': 'Finished detecting all objects on image',
    'before_after': 'Before/After',
    'remove': 'Remove',
    'yes': 'Yes',
    'no': 'No',
    'cancel': 'Cancel',
    'replace': 'Replace',
    'replace_with': 'Replace with',
    'describe_redesign': 'Describe your vision for the ideal design of your interior/exterior.',
    'describe_inspire': 'Imagine your ideal living space. Is it modern, rustic, or something else? Describe it in detail.',
    'avoid_these_things': 'Avoid these things',
    'positive_prompt_placeholder': 'A beautiful and modern living room with nice flowers and carpet',
    'negative_prompt_placeholder': 'Ugly, Deformed, Bad quality',
    'delete_image': 'Permanently delete image',
    'delete_image_are_you_sure': 'Are you sure you want to delete the image?',
    'delete_workspace': 'Permanently delete workspace',
    'delete_workspace_are_you_sure': 'Are you sure you want to delete the workspace? This will also delete ALL images in workspace',
    'copyright_reserved': 'All Rights Reserved',
    'no_workspace_available': 'No workspace available. Please create one.',
    'finished_processing_out_of_sync': "Finished processing your request. If detected objects are out of sync, please click on 'Process image objects' to resync.",
    'processing_taking_long_retry': 'Our system is taking longer than usual. Retrying...',
    'not_selected_remove': 'Please select an object to REMOVE by tapping or clicking on the image',
    'not_selected_replace': 'Please select an object to REPLACE by tapping or clicking on the image',
    'untitled': 'Untitled',
    'tap_click_object_to_replace': 'Please tap or click an object to replace',
    'replacing_selected_object': 'Replacing selected object',
    'generating_inspiration': 'Generating your inspiration design. This will generate a new image, it will not modify your existing image. Use design my image to modify you existing image. Please wait a moment (8 seconds)...',
    'reimagining_your_space': 'Reimagining your space...',
    'enhancing_your_image': 'Enhancing your image',
    'thank_you_start_designing': 'Start designing now by creating a workspace!',
    'thank_you': 'Thank you',
    'for_subs': 'for Subscribing!',
    'erasing_selected': 'Erasing selected object',
    'select_an_object': 'Please select an object by clicking or tapping the image',
    'turn_off': "Turn off",
    'help_alert': " To get the best results. Please be as descriptive as possible when providing instructions to the Artificial intelligence. Use redesign to remix designs as well.",
    'or': "Or",
    'inspire_me': "Inspire Me",
    'image_uploaded': "Image uploaded. Try using 'Redesign My Image' to modify your interior/exterior",
    'default_workspace_name': 'My Designs',
    'Select': 'Select',
    'Change': 'Change',
    'Please select an item.': 'Please select an item.',
    'Space Type': 'Space Type',
    'Specific Interior Space': 'Specific Interior Space',
    'Specific Exterior Space': 'Specific Exterior Space',
    'Style': 'Style',
    'Item to Emphasize': 'Item to Emphasize',
    'What to Emphasize': 'What to Emphasize',
    'Write the': 'Write the',
    'Color Scheme': 'Color Scheme',
    'Budget Constraints': 'Budget Constraints',
    'Previous': 'Previous',
    'Next': 'Next',
    'Redesign': 'Redesign',
    'Selected Options Summary': 'Selected Options Summary',
    'Space Type:': 'Space Type:',
    'Specific Space:': 'Specific Space:',
    'Style:': 'Style:',
    'Emphasis:': 'Emphasis:',
    'What to Emphasize:': 'What to Emphasize:',
    'Detail Option:': 'Detail Option:',
    'Color Scheme:': 'Color Scheme:',
    'Budget:': 'Budget:',
    'Download': 'Download',
    're_use': 'ReDo',
    'host': 'TheHomedecorAI.com',
    'Download_with_watermark': 'Download with watermark',
    'invalid_prompt': 'Invalid description. Please try again.',
    'free_form': 'I want to describe exactly what I want (Free Form Text)',
    'use_selection_form': 'I want to use the selection form',
};
