export const decorFormDataEN_KV: Record<string, string> = {
    "interior": "interior",
    "exterior": "exterior",

    // spaceTypes interior
    "Living Room": "Living Room",
    "Bedroom": "Bedroom",
    "Kitchen": "Kitchen",
    "Bathroom": "Bathroom",
    "Dining Room": "Dining Room",
    "Home Office": "Home Office",
    "Nursery": "Nursery",
    "Home Theater": "Home Theater",
    "Game Room": "Game Room",
    "Walk-In Closet": "Walk-In Closet",
    "Home Gym": "Home Gym",
    "Study/Library": "Study/Library",
    "Mudroom": "Mudroom",
    "Sunroom": "Sunroom",
    "Walls": "Walls",
    "Bar Area": "Bar Area",
    "Walk-In Pantry": "Walk-In Pantry",
    "Laundry Room": "Laundry Room",
    "Guest Room": "Guest Room",
    "Attic Space": "Attic Space",
    "Wine Cellar": "Wine Cellar",

    // spaceTypes exterior
    "Front Yard": "Front Yard",
    "Backyard": "Backyard",
    "Facade": "Facade",
    "Patio": "Patio",
    "Garden": "Garden",
    "Porch": "Porch",
    "Balcony": "Balcony",
    "Pool Area": "Pool Area",
    "Outdoor Kitchen": "Outdoor Kitchen",
    "Roof Deck": "Roof Deck",
    "Courtyard": "Courtyard",
    "Rooftop Garden": "Rooftop Garden",
    "Driveway": "Driveway",
    "Playground Area": "Playground Area",
    "Outdoor Barbecue Area": "Outdoor Barbecue Area",
    "Sports Court": "Sports Court",
    "Shed/Storage Area": "Shed/Storage Area",
    "Dog Run": "Dog Run",
    "Greenhouse": "Greenhouse",
    "Carport": "Carport",
    "Boat Dock/Pier": "Boat Dock/Pier",

    // styles
    "Modern": "Modern",
    "Traditional": "Traditional",
    "Minimalist": "Minimalist",
    "Industrial": "Industrial",
    "Scandinavian": "Scandinavian",
    "Mediterranean": "Mediterranean",
    "Bohemian": "Bohemian",
    "Coastal": "Coastal",
    "Rustic": "Rustic",
    "Vintage": "Vintage",

    // emphasisElements
    "Art Pieces": "Art Pieces",
    "Indoor Plants": "Indoor Plants",
    "Statement Furniture": "Statement Furniture",
    "Light Fixtures": "Light Fixtures",
    "Decorative Rugs": "Decorative Rugs",
    "Wallpapers": "Wallpapers",
    "Mirrors": "Mirrors",
    "Sculptures": "Sculptures",
    "Bookshelves": "Bookshelves",
    "Statues": "Statues",

    // colorSchemes
    "Neutral": "Neutral",
    "Vibrant": "Vibrant",
    "Monochrome": "Monochrome",
    "Pastel": "Pastel",
    "Earthy Tones": "Earthy Tones",
    "Bold Contrasts": "Bold Contrasts",
    "Cool Blues": "Cool Blues",
    "Warm Reds": "Warm Reds",
    "Nature-Inspired": "Nature-Inspired",
    "Black and White": "Black and White",

    // budgetConstraints
    "Low Budget": "Low Budget",
    "Moderate Budget": "Moderate Budget",
    "High Budget": "High Budget",
    "No Budget Constraints": "No Budget Constraints",

    // materialCategories
    "Flooring": "Flooring",
    "Furniture": "Furniture",
    "Wall Coverings": "Wall Coverings",
    "Countertops": "Countertops",
    "Cabinetry": "Cabinetry",

    // materials for each category
    "Hardwood": "Hardwood",
    "Tile": "Tile",
    "Carpet": "Carpet",
    "Vinyl": "Vinyl",
    "Laminate": "Laminate",
    "Wood": "Wood",
    "Metal": "Metal",
    "Glass": "Glass",
    "Leather": "Leather",
    "Fabric": "Fabric",
    "Paint": "Paint",
    "Wallpaper": "Wallpaper",
    "Paneling": "Paneling",
    "Stone": "Stone",
    "Granite": "Granite",
    "Quartz": "Quartz",
    "Marble": "Marble",
    "Concrete": "Concrete",
    "Melamine": "Melamine",
    "Stainless Steel": "Stainless Steel"
};
