export const decorFormDataES = {
    "spaces": ["interior", "exterior"],
    "spaceTypes": {
        "interior": [
            "Sala de estar",
            "Dormitorio",
            "Cocina",
            "Baño",
            "Comedor",
            "Oficina en casa",
            "Guardería",
            "Cine en casa",
            "Sala de juegos",
            "Vestidor",
            "Gimnasio en casa",
            "Estudio/Biblioteca",
            "Sala de barro",
            "Solarium",
            "Paredes",
            "Área de bar",
            "Despensa",
            "Cuarto de lavado",
            "Habitación de invitados",
            "Espacio del ático",
            "Bodega"
        ],
        "exterior": [
            "Patio delantero",
            "Patio trasero",
            "Fachada",
            "Patio",
            "Paredes",
            "Jardín",
            "Porche",
            "Balcón",
            "Área de la piscina",
            "Cocina exterior",
            "Terraza en la azotea",
            "Patio interior",
            "Jardín en la azotea",
            "Entrada de auto",
            "Área de juegos",
            "Área de barbacoa al aire libre",
            "Cancha de deportes",
            "Cobertizo/Área de almacenamiento",
            "Zona de juegos para perros",
            "Invernadero",
            "Cochera",
            "Muelle para botes/Puente"
        ]
    },
    "styles": [
        "Moderno",
        "Tradicional",
        "Minimalista",
        "Industrial",
        "Escandinavo",
        "Mediterráneo",
        "Bohemio",
        "Costero",
        "Rústico",
        "Vintage"
    ],
    "emphasisElements": {
        "interior": [
            {
                "name": "Color",
                "detailedSelectionOptions": ["Color de la pared", "Color de los muebles", "Color de acento"]
            },
            {
                "name": "Paredes",
                "detailedSelectionOptions": ["Plantas", "Color", "Iluminación"]
            },
            {
                "name": "Muebles",
                "detailedSelectionOptions": ["Tipo", "Material", "Color"]
            },
            {
                "name": "Iluminación",
                "detailedSelectionOptions": ["Tipo", "Estilo de accesorio", "Brillo"]
            },
            {
                "name": "Arte",
                "detailedSelectionOptions": ["Estilo de arte", "Tamaño", "Color"]
            },
            {
                "name": "Tapetes/Alfombras",
                "detailedSelectionOptions": ["Textura", "Color", "Tamaño", "Patrón", "Material"]
            },
            {
                "name": "Decoración de pared",
                "detailedSelectionOptions": ["Cuadros", "Espejos", "Papeles pintados", "Estanterías"]
            },
            {
                "name": "Plantas",
                "detailedSelectionOptions": ["Plantas de interior", "Macetas", "Colores de plantas"]
            },
            {
                "name": "Texturas",
                "detailedSelectionOptions": ["Tela", "Piedra", "Madera", "Metal", "Cristal"]
            },
            {
                "name": "Ventanas",
                "detailedSelectionOptions": ["Cortinas", "Persianas", "Tinte para ventanas", "Color del marco de la ventana"]
            },
            {
                "name": "Chimenea",
                "detailedSelectionOptions": ["Tipo", "Estilo de repisa", "Acabado de la chimenea"]
            },
            {
                "name": "Estanterías/Estanterías de libros",
                "detailedSelectionOptions": ["Diseño", "Material", "Color"]
            },
            {
                "name": "Centro de entretenimiento en el hogar",
                "detailedSelectionOptions": ["Tamaño del televisor", "Diseño de la consola multimedia", "Sistema de altavoces"]
            },
            {
                "name": "Chimenea empotrada",
                "detailedSelectionOptions": ["Diseño", "Material", "Color"]
            },
            {
                "name": "Característica de agua interior",
                "detailedSelectionOptions": ["Fuente", "Cascada de pared", "Estanque interior"]
            },
            {
                "name": "Techo llamativo",
                "detailedSelectionOptions": ["Diseño de techo", "Lámpara de araña", "Color del techo"]
            },
            {
                "name": "Asiento junto a la ventana",
                "detailedSelectionOptions": ["Cojín para la ventana", "Cojines", "Cubierta para ventana"]
            },
            {
                "name": "Pared llamativa",
                "detailedSelectionOptions": ["Color de pared de acento", "Papel pintado", "Textura"]
            },
            {
                "name": "Bar en casa",
                "detailedSelectionOptions": ["Estilo del mostrador de bar", "Taburetes de bar", "Accesorios de bar"]
            },
            {
                "name": "Espacio de oficina en casa",
                "detailedSelectionOptions": ["Estilo de escritorio", "Silla", "Estanterías"]
            },
            {
                "name": "Soluciones de almacenamiento oculto",
                "detailedSelectionOptions": ["Armarios empotrados", "Almacenamiento debajo de la escalera", "Organizadores de armarios"]
            }
        ],
        "exterior": [
            {
                "name": "Paisajismo",
                "detailedSelectionOptions": ["Plantas", "Paisaje duro", "Iluminación", "Elementos de agua"]
            },
            {
                "name": "Paredes",
                "detailedSelectionOptions": ["Plantas", "Color", "Iluminación"]
            },
            {
                "name": "Muebles de exterior",
                "detailedSelectionOptions": ["Tipo", "Material", "Color"]
            },
            {
                "name": "Iluminación",
                "detailedSelectionOptions": ["Tipo", "Estilo de accesorio", "Brillo"]
            },
            {
                "name": "Fachada",
                "detailedSelectionOptions": ["Tipo", "Color"]
            },
            {
                "name": "Elementos de agua",
                "detailedSelectionOptions": ["Fuente", "Estanque", "Cascada"]
            },
            {
                "name": "Pérgola/Cenador",
                "detailedSelectionOptions": ["Material", "Tamaño", "Color"]
            },
            {
                "name": "Camas de jardín",
                "detailedSelectionOptions": ["Selección de plantas", "Diseño de la cama", "Mulch/Grava"]
            },
            {
                "name": "Senderos",
                "detailedSelectionOptions": ["Material del sendero", "Forma del sendero", "Iluminación del sendero"]
            },
            {
                "name": "Plataformas/Patios",
                "detailedSelectionOptions": ["Material de la plataforma", "Pavimentos de patio", "Color de la plataforma/patio"]
            },
            {
                "name": "Área de la piscina",
                "detailedSelectionOptions": ["Tipo de piscina", "Revestimiento de piscina", "Iluminación de piscina"]
            },
            {
                "name": "Cercas",
                "detailedSelectionOptions": ["Tipo de valla", "Altura de la valla", "Color de la valla"]
            },
            {
                "name": "Isla de cocina exterior",
                "detailedSelectionOptions": ["Estilo de la isla", "Material de la encimera", "Electrodomésticos exteriores"]
            },
            {
                "name": "Fogata/Chimenea exterior",
                "detailedSelectionOptions": ["Diseño de fogata", "Tipo de chimenea", "Material de fogata/chimenea"]
            },
            {
                "name": "Piscina",
                "detailedSelectionOptions": ["Forma de la piscina", "Tamaño de la piscina", "Características de la piscina"]
            },
            {
                "name": "Área de descanso al aire libre",
                "detailedSelectionOptions": ["Muebles de descanso", "Alfombras exteriores", "Calefacción exterior"]
            },
            {
                "name": "Caminos de jardín",
                "detailedSelectionOptions": ["Material del camino", "Forma del camino", "Iluminación del camino"]
            },
            {
                "name": "Cubierta/Patio",
                "detailedSelectionOptions": ["Tipo de cubierta", "Pavimentos de patio", "Color de la cubierta/patio"]
            },
            {
                "name": "Ducha exterior",
                "detailedSelectionOptions": ["Tipo de ducha", "Material de la ducha", "Accesorios de la ducha"]
            },
            {
                "name": "Estanque o elemento de agua",
                "detailedSelectionOptions": ["Tamaño del estanque", "Tipo de elemento de agua", "Material del elemento de agua"]
            },
            {
                "name": "Celosía o pérgola",
                "detailedSelectionOptions": ["Estilo de celosía/pérgola", "Material", "Color"]
            },
            {
                "name": "Muebles de exterior personalizados",
                "detailedSelectionOptions": ["Tipo de muebles", "Material", "Colores de cojines"]
            }
        ]
    },
    "colorSchemes": [
        "Neutro",
        "Vibrante",
        "Monocromático",
        "Pastel",
        "Tonos terrosos",
        "Contrastes audaces",
        "Azules frescos",
        "Rojos cálidos",
        "Inspirado en la naturaleza",
        "Blanco y negro"
    ],
    "budgetConstraints": [
        "Presupuesto bajo",
        "Presupuesto moderado",
        "Presupuesto alto",
        "Sin restricciones de presupuesto"
    ],
    "materialCategories": ["Suelos", "Muebles", "Revestimientos de pared", "Encimeras", "Cabinas"],
    "materials": {
        "Suelos": ["Madera dura", "Azulejo", "Moqueta", "Vinilo", "Laminado"],
        "Muebles": ["Madera", "Metal", "Cristal", "Cuero", "Tela"],
        "Revestimientos de pared": ["Pintura", "Papel pintado", "Azulejo", "Revestimiento", "Piedra"],
        "Encimeras": ["Granito", "Cuarzo", "Mármol", "Laminado", "Hormigón"],
        "Cabinas": ["Madera", "Melamina", "Cristal", "Acero inoxidable", "Laminado"]
    }
};
