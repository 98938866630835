import { I18n } from "aws-amplify";
import React, { useContext, useState } from "react";
import { Spinner } from "reactstrap";
import DesignHubContext from "../hooks/DesignHub/createContext";
import { ImageMaskState } from "../../helpers/Interfaces";
import { dataURItoBlob } from "../../helpers/maskUtils";
import { invokeSD2 } from "../../services/aws/sagemaker/aws_sagemaker_helper";
import { ImageFolderType, uploadImage } from "../../services/aws/s3/aws_s3_helper";
import { ApiResponse, DesignImage } from "../helpers/Interfaces";
import { showFinishedProcessingMessage, showNonPaymentMessage, showRetryingMessage, showSystemError } from "../../helpers/ui_errors";
import HomeRedesignForm, { DesignType } from "../../pages/DesignHub/Editor/HomeDecorForm";

export interface InspireMeFormProps {
    setCreditsRemaining: Function;
    setDesignImage: Function;
    addImageInList: Function;
}

const maxRetryCount = 3;

export const InspireMeForm = (props: InspireMeFormProps) => {

    const {
        maskImageState: [maskImageState, setMaskImageState],
        alert: [, setAlert],
        currentWorkspace: [currentWorkspace,],
        replaceMode: [replaceMode,]
    } = useContext(DesignHubContext)!;

    const [selectedValues, setSelectedValues] = useState<any>({
        selectedSpaceType: '',
        selectedSpecificSpaceType: '',
        selectedStyle: '',
        selectedEmphasis: '',
        selectedWhatToEmphasize: '',
        detailOptionInput: '',
        selectedColorScheme: '',
        selectedBudget: '',
    });

    const [imagePrompt, setImagePrompt] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    function inspireMe(generatedPrompt: string) {
        setAlert({
            alertType: "warning",
            alertContent: I18n.get("generating_inspiration"),
        });

        setImagePrompt(generatedPrompt);

        setMaskImageState(ImageMaskState.Inspiring);
        setIsProcessing(true);

        function executeReplaceWithRetry(retryCount: number) {
            if (retryCount > maxRetryCount) {
                showSystemError(setAlert, setIsProcessing);
                return;
            }

            let payLoad = {
                "prompt": `${generatedPrompt}. interior design magazine, warm atmosphere, photorealistic, realistic light, wide angle, many details,  --ar 3:2 --beta --upbeta --upbeta :Residential home high end futuristic interior, olson kundig::1 Interior Design by Dorothy Draper, maison de verre, axel vervoordt::2 award winning photography of an indoor-outdoor living library space, minimalist modern designs::1 high end indoor/outdoor residential living space, rendered in vray, rendered in octane, rendered in unreal engine, architectural photography, photorealism, featured in dezeen, cristobal palma::2.5 chaparral landscape outside, black surfaces/textures for furnishings in outdoor space::1 --q 2 --ar 4:7 : haussmannien , interior neo classic. Beautiful. Professional. High Quality, HDR, UHD, 64K,Highly detailed,Studio lighting, 3 point lighting, flash with softbox, 80mm`,
                "negative_prompt": `Split image, out of frame, amputee, mutated, mutation, deformed, severed, dismembered, corpse, photograph, poorly drawn, bad anatomy, blur, blurry, lowres, bad hands, error, missing fingers, extra digit, fewer digits, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, artist name, ugly, symbol, hieroglyph,, extra fingers,  six fingers per hand, four fingers per hand, disfigured hand, monochrome, missing limb, disembodied limb, linked limb, connected limb, interconnected limb,  broken finger, broken hand, broken wrist, broken leg, split limbs, no thumb, missing hand, missing arms, missing legs, fused finger, fused digit, missing digit, bad digit, extra knee, extra elbow, storyboard, split arms, split hands, split fingers, twisted fingers, disfigured butt`,
                "style_preset": "photographic",
                "cfg_scale": 10,
                "steps": 30,
                "mode": "text_to_image"
            }

            Promise.resolve(invokeSD2(payLoad, ""))
                .then((result?: ApiResponse<DesignImage>) => {
                    props.setCreditsRemaining(result?.creditsRemaining);

                    if (!result) return;
                    Promise.resolve(uploadImage(dataURItoBlob(result.output.src),
                        "inspire",
                        ImageFolderType.Other,
                        currentWorkspace)).then((result) => {
                            let n = {
                                ...result,
                            };
                            props.setDesignImage(n);
                            props.addImageInList(n);
                            setMaskImageState(ImageMaskState.Done);
                            setIsProcessing(false);
                            // For design for me mode, auto detect objects
                            showFinishedProcessingMessage(setAlert, setIsProcessing);
                        });
                })
                .catch((error) => {
                    if (error.status === 402) {
                        showNonPaymentMessage(setAlert);
                    } else {
                        showRetryingMessage(setAlert, retryCount + 1)
                        executeReplaceWithRetry(retryCount + 1);
                    }
                }).finally(() => {
                    setIsProcessing(false);
                });
        }
        executeReplaceWithRetry(1)
    }

    return (
        <>
            {isProcessing && <Spinner className="primary" />}
            <HomeRedesignForm
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                redesignImage={inspireMe}
                designType={DesignType.Inspire}
                generatedQuery={imagePrompt} />
        </>
    );
}

