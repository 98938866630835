// const kke = flattenObject(decorFormDataES);
export const decorFormDataES_KV: Record<string, string> = {
    "interior": "interior",
    "exterior": "exterior",
    "Sala de estar": "Living room",
    "Dormitorio": "Bedroom",
    "Cocina": "Kitchen",
    "Baño": "Bathroom",
    "Comedor": "Dining room",
    "Oficina en casa": "Home office",
    "Guardería": "Nursery",
    "Cine en casa": "Home theater",
    "Sala de juegos": "Playroom",
    "Vestidor": "Dressing room",
    "Gimnasio en casa": "Home gym",
    "Estudio/Biblioteca": "Study/Library",
    "Sala de barro": "Mudroom",
    "Solarium": "Solarium",
    "Paredes": "Walls",
    "Área de bar": "Bar area",
    "Despensa": "Pantry",
    "Cuarto de lavado": "Laundry room",
    "Habitación de invitados": "Guest room",
    "Espacio del ático": "Attic space",
    "Bodega": "Cellar",
    "Patio delantero": "Front yard",
    "Patio trasero": "Backyard",
    "Fachada": "Facade",
    "Patio": "Courtyard",
    "Jardín": "Garden",
    "Porche": "Porch",
    "Balcón": "Balcony",
    "Área de la piscina": "Pool area",
    "Cocina exterior": "Outdoor kitchen",
    "Terraza en la azotea": "Rooftop terrace",
    "Patio interior": "Inner courtyard",
    "Jardín en la azotea": "Rooftop garden",
    "Entrada de auto": "Driveway",
    "Área de juegos": "Playground",
    "Área de barbacoa al aire libre": "Outdoor barbecue area",
    "Cancha de deportes": "Sports court",
    "Cobertizo/Área de almacenamiento": "Shed/Storage area",
    "Zona de juegos para perros": "Dog play area",
    "Invernadero": "Greenhouse",
    "Cochera": "Carport",
    "Muelle para botes/Puente": "Boat dock/Bridge",
    "Moderno": "Modern",
    "Tradicional": "Traditional",
    "Minimalista": "Minimalist",
    "Industrial": "Industrial",
    "Escandinavo": "Scandinavian",
    "Mediterráneo": "Mediterranean",
    "Bohemio": "Bohemian",
    "Costero": "Coastal",
    "Rústico": "Rustic",
    "Vintage": "Vintage",
    "emphasisElements.name": "Custom outdoor furniture",
    "Color de la pared": "Wall color",
    "Color de los muebles": "Furniture color",
    "Color de acento": "Accent color",
    "Plantas": "Plants",
    "Color": "Color",
    "Iluminación": "Lighting",
    "Tipo": "Type",
    "Material": "Material",
    "Estilo de accesorio": "Accessory style",
    "Brillo": "Brightness",
    "Estilo de arte": "Art style",
    "Tamaño": "Size",
    "Textura": "Texture",
    "Patrón": "Pattern",
    "Cuadros": "Frames",
    "Espejos": "Mirrors",
    "Papeles pintados": "Wallpapers",
    "Estanterías": "Shelves",
    "Plantas de interior": "Indoor plants",
    "Macetas": "Pots",
    "Colores de plantas": "Plant colors",
    "Tela": "Fabric",
    "Piedra": "Stone",
    "Madera": "Wood",
    "Metal": "Metal",
    "Cristal": "Glass",
    "Cortinas": "Curtains",
    "Persianas": "Blinds",
    "Tinte para ventanas": "Window tint",
    "Color del marco de la ventana": "Window frame color",
    "Estilo de repisa": "Shelf style",
    "Acabado de la chimenea": "Fireplace finish",
    "Diseño": "Design",
    "Tamaño del televisor": "TV size",
    "Diseño de la consola multimedia": "Media console design",
    "Sistema de altavoces": "Speaker system",
    "Fuente": "Fountain",
    "Cascada de pared": "Wall waterfall",
    "Estanque interior": "Indoor pond",
    "Diseño de techo": "Ceiling design",
    "Lámpara de araña": "Chandelier",
    "Color del techo": "Ceiling color",
    "Cojín para la ventana": "Window cushion",
    "Cojines": "Cushions",
    "Cubierta para ventana": "Window covering",
    "Color de pared de acento": "Accent wall color",
    "Papel pintado": "Wallpaper",
    "Estilo del mostrador de bar": "Bar countertop style",
    "Taburetes de bar": "Bar stools",
    "Accesorios de bar": "Bar accessories",
    "Estilo de escritorio": "Desk style",
    "Silla": "Chair",
    "Armarios empotrados": "Built-in wardrobes",
    "Almacenamiento debajo de la escalera": "Under-stairs storage",
    "Organizadores de armarios": "Wardrobe organizers",
    "Paisaje duro": "Hardscape",
    "Elementos de agua": "Water features",
    "Estanque": "Pond",
    "Cascada": "Waterfall",
    "Selección de plantas": "Plant selection",
    "Diseño de la cama": "Bed design",
    "Mulch/Grava": "Mulch/Gravel",
    "Material del sendero": "Pathway material",
    "Forma del sendero": "Pathway shape",
    "Iluminación del sendero": "Pathway lighting",
    "Material de la plataforma": "Deck material",
    "Pavimentos de patio": "Patio paving",
    "Color de la plataforma/patio": "Deck/patio color",
    "Tipo de piscina": "Pool type",
    "Revestimiento de piscina": "Pool lining",
    "Iluminación de piscina": "Pool lighting",
    "Tipo de valla": "Fence type",
    "Altura de la valla": "Fence height",
    "Color de la valla": "Fence color",
    "Estilo de la isla": "Island style",
    "Material de la encimera": "Countertop material",
    "Electrodomésticos exteriores": "Outdoor appliances",
    "Diseño de fogata": "Firepit design",
    "Tipo de chimenea": "Fireplace type",
    "Material de fogata/chimenea": "Firepit/fireplace material",
    "Forma de la piscina": "Pool shape",
    "Tamaño de la piscina": "Pool size",
    "Características de la piscina": "Pool features",
    "Muebles de descanso": "Lounge furniture",
    "Alfombras exteriores": "Outdoor rugs",
    "Calefacción exterior": "Outdoor heating",
    "Material del camino": "Pathway material",
    "Forma del camino": "Pathway shape",
    "Iluminación del camino": "Pathway lighting",
    "Tipo de cubierta": "Deck type",
    "Color de la cubierta/patio": "Deck/patio color",
    "Tipo de ducha": "Shower type",
    "Material de la ducha": "Shower material",
    "Accesorios de la ducha": "Shower accessories",
    "Tamaño del estanque": "Pond size",
    "Tipo de elemento de agua": "Water feature type",
    "Material del elemento de agua": "Water feature material",
    "Estilo de celosía/pérgola": "Lattice/pergola style",
    "Tipo de muebles": "Furniture type",
    "Colores de cojines": "Cushion colors",
    "Neutro": "Neutral",
    "Vibrante": "Vibrant",
    "Monocromático": "Monochromatic",
    "Pastel": "Pastel",
    "Tonos terrosos": "Earthy tones",
    "Contrastes audaces": "Bold contrasts",
    "Azules frescos": "Cool blues",
    "Rojos cálidos": "Warm reds",
    "Inspirado en la naturaleza": "Nature-inspired",
    "Blanco y negro": "Black and white",
    "Presupuesto bajo": "Low budget",
    "Presupuesto moderado": "Moderate budget",
    "Presupuesto alto": "High budget",
    "Sin restricciones de presupuesto": "No budget constraints",
    "Suelos": "Flooring",
    "Muebles": "Furniture",
    "Revestimientos de pared": "Wall coverings",
    "Encimeras": "Countertops",
    "Cabinas": "Cabinets",
    "Madera dura": "Hardwood",
    "Azulejo": "Tile",
    "Moqueta": "Carpet",
    "Vinilo": "Vinyl",
    "Laminado": "Laminate",
    "Cuero": "Leather",
    "Pintura": "Paint",
    "Revestimiento": "Siding",
    "Granito": "Granite",
    "Cuarzo": "Quartz",
    "Mármol": "Marble",
    "Hormigón": "Concrete",
    "Melamina": "Melamine",
    "Acero inoxidable": "Stainless steel"
};
