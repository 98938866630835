import { Auth, I18n } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { enStripeDict } from "../../i18n/dictionaries/stripe/en";
export interface StripePricingTableProps {
    lead: string;
    userId: string;
    email: string;
    pricingTableId?: string;
    publishableKey?: string;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

const StripePricingTable = (props: any) => {
    const pricingTableIdTest = "prctbl_1NqdLPIM79T2sGy3aMKAKjqO";
    const publishableKeyTest = `pk_test_51NqU7BIM79T2sGy3t9KRfMI9urKwhAoTaqrb0LZOIAbtgqq16KaC6IsAslkMTL4P55MZwkDqlUOa0Rmj6HLZSVU000lFdSZvD0`;

    const pricingTableId = enStripeDict["pricing-table-id"];
    const publishableKey = enStripeDict['publishable-key'];

    const [userId, setUserId] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    useEffect(() => {
        Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
            if (userInfo && userInfo.attributes) {
                setUserId(userInfo.attributes.sub);
                setEmail(userInfo.attributes.email);
            }
        });
    }, []);
    return (
        <div>
            {
                !props.hideLead &&
                <h2 className="text-center">
                    {I18n.get(props.lead)}
                </h2>
            }

            <stripe-pricing-table
                pricing-table-id={I18n.get('pricing-table-id') || pricingTableId}
                publishable-key={I18n.get('publishable-key') || publishableKey}
                client-reference-id={userId}
                customer-email={email}>
            </stripe-pricing-table>
        </div>
    );
};
export default withTranslation()(StripePricingTable);
