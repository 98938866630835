import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { ImageLoader } from "./imageLoader";
import DesignHubContext from "../../../components/hooks/DesignHub/createContext";
import { MdDownload, MdLightbulb } from "react-icons/md";
import { FaDownload, FaEye, FaMagic, FaTrash, FaUndo } from "react-icons/fa";
import { ImageMaskState } from "../../../helpers/Interfaces";
import { ApiResponse, DesignImage } from "../../../components/helpers/Interfaces";

// Mask processor
import { saveAs } from 'file-saver';
import { dataURItoBlob } from "../../../helpers/maskUtils";
import { removeImage, updateImage } from "../../../services/aws/s3/aws_s3_helper";
import { invokeSD2 } from "../../../services/aws/sagemaker/aws_sagemaker_helper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ReactCompareImage from "react-compare-image";
import { I18n } from "aws-amplify";
import { showFinishedProcessingMessage, showNonPaymentMessage, showObjectNotSelectedWarning, showRetryingMessage, showSystemError } from "../../../helpers/ui_errors";
import HomeRedesignForm, { DesignType } from "./HomeDecorForm";
import { TbReplace } from "react-icons/tb";
const cv = require('opencv.js');
const utf8 = require('utf8');

export interface EditorProps {
    designImage?: DesignImage;
    currentImages: DesignImage[];
    setDesignImage: Function;
    removeImageFromList: Function;
    addImageInList: Function;
    replaceImageInList: Function;
    setCreditsRemaining: Function;
    getImageDataUrlFromCanvas: Function;
}

enum MaskSource {
    MASK_IMAGE_BLACK,
    MASK_IMAGE_WHITE,
}

export const Editor = (props: EditorProps) => {
    const {
        image: [image,],
        maskImageState: [maskImageState, setMaskImageState],
        alert: [, setAlert],
        isLoading: [isLoading,],
        replaceMode: [replaceMode, setReplaceMode]
    } = useContext(DesignHubContext)!;

    useEffect(() => {
        if (!isLoading) {
            setImageMaskState(ImageMaskState.Deselected);
        }
    }, [isLoading]);

    const [selectedValues, setSelectedValues] = useState<any>({
        selectedSpaceType: '',
        selectedSpecificSpaceType: '',
        selectedStyle: '',
        selectedEmphasis: '',
        selectedWhatToEmphasize: '',
        detailOptionInput: '',
        selectedColorScheme: '',
        selectedBudget: '',
    });

    const [selectedImage, setSelectedImage] = useState<number>(0);

    const [isShowConfirmDeleteImage, setIsShowConfirmDeleteImage] = useState<boolean>(false);
    const [isLightBoxEnabled, setIsLightBoxEnabled] = useState<boolean>(false);
    const [isShowImageDiff, setShowImageDiff] = useState<boolean>(false);
    const [, setIsProcessing] = useState<boolean>(false);

    const canvasRef = useRef<any>(null);

    const [imagePrompt, setImagePrompt] = useState<string>("");
    const [, setMaskSource] = useState<MaskSource>(MaskSource.MASK_IMAGE_BLACK);

    const [enhancedImage, setEnhancedImage] = useState<DesignImage>();

    const selectedThumbnailClass = "border-solid border-3 border-slate-600";
    const unselectedThumbnailClass = "border-solid border-3 border-transparent";

    const maxRetryCount = 4;

    function changeSelectedImage(e: any, imageIdx: number) {
        if (e) {
            e.preventDefault();
        }
        setShowImageDiff(false);

        const selectedImg: DesignImage = props.currentImages[imageIdx];
        deselectSelectedObject(e);
        setSelectedImage(imageIdx);
        props.setDesignImage(selectedImg);
        setAlert({});
    }

    function setImageMaskState(state: ImageMaskState) {
        setMaskImageState(state);
    }

    function deselectSelectedObject(e: any) {
        setImageMaskState(ImageMaskState.Select)
    }

    function magicReplace(imagePrompt: string) {
        setAlert({
            alertType: "warning",
            alertContent: I18n.get("reimagining_your_space"),
        });

        setImagePrompt(imagePrompt);

        setImageMaskState(ImageMaskState.Replacing);
        setIsProcessing(true);


        function executeReplaceWithRetry(retryCount: number) {
            if (retryCount > maxRetryCount) {
                showSystemError(setAlert, setIsProcessing);
                return;
            }

            Promise.resolve(toDataUrl(props.designImage?.src)).then((_dataUrl) => {

                let payLoad = {
                    "prompt": `${imagePrompt}`,
                    "negative_prompt": "",
                    "image": utf8.encode(_dataUrl.split(',')[1]),
                    "image_strength": 0.50,
                    "style_preset": "photographic",
                    "cfg_scale": 10,
                    "steps": 30,
                    "mode": "image_to_image"
                }

                Promise.resolve(invokeSD2(payLoad, props.designImage?.key || ""))
                    .then((result?: ApiResponse<DesignImage>) => {
                        props.setCreditsRemaining(result?.creditsRemaining);

                        if (!result) return;
                        Promise.resolve(updateImage(dataURItoBlob(result.output.src), result.output.key)).then((result) => {
                            let n = {
                                ...result,
                                previous: {
                                    ...props.designImage,
                                    src: _dataUrl,
                                }
                            };
                            props.setDesignImage(n);
                            props.replaceImageInList(n);
                            setImageMaskState(ImageMaskState.Done);
                            setIsProcessing(false);

                            showFinishedProcessingMessage(setAlert, setIsProcessing);
                        });
                    })
                    .catch((error) => {
                        if (error.status === 402) {
                            showNonPaymentMessage(setAlert);
                        } else {
                            showRetryingMessage(setAlert, retryCount + 1)
                            executeReplaceWithRetry(retryCount + 1);
                        }
                    })
            }).finally(() => {
                setIsProcessing(false);
            });
        }
        executeReplaceWithRetry(1)
    }

    function enhanceImage(isDownload: boolean) {
        if (!props.designImage) return;
        setIsProcessing(true);
        setAlert({
            alertType: "warning",
            alertContent: I18n.get("enhancing_your_image"),
        });

        if (enhancedImage && enhancedImage?.src === props.designImage?.src) {
            if (isDownload) saveAs(enhancedImage.src || "", `${props.designImage?.key}_upscaled.png`);
            else setIsLightBoxEnabled(true);
            return;
        }
        setImageMaskState(ImageMaskState.Replacing);
        Promise.resolve(toDataUrl(props.designImage?.src)).then((_dataUrl) => {
            _dataUrl = utf8.encode(_dataUrl.split(',')[1]);

            let payLoad = {
                "image": _dataUrl,
                "mode": "upscale"
            }

            Promise.resolve(invokeSD2(payLoad, props.designImage?.key || ""))
                .then((result?: ApiResponse<DesignImage>) => {
                    props.setCreditsRemaining(result?.creditsRemaining);

                    if (!result) return;
                    if (isDownload) saveAs(result.output.src || "", `${props.designImage?.key}_upscaled.png`);
                    setImageMaskState(ImageMaskState.Done);
                    setEnhancedImage(result.output);
                    setIsLightBoxEnabled(true);
                    setIsProcessing(false);

                    showFinishedProcessingMessage(setAlert, setIsProcessing);
                })
        }).finally(() => {
            setIsProcessing(false);
        });
    }

    function isCurrentImageMaskState(state: ImageMaskState): boolean {
        return (state && state) === maskImageState;
    }

    async function toDataUrl(imageUrl?: string, width = 1344, height = 768): Promise<any> {
        if (!imageUrl) return;
        return fetch(imageUrl, {
            headers: {
                'Cache-Control': 'no-cache'
            }
        })
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    if (!ctx) return reject();

                    if (img.height > img.width) {
                        width = 768;
                        height = 1344;
                    }

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);
                    canvas.toBlob((resizedBlob) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        if (!resizedBlob) return reject();
                        reader.readAsDataURL(resizedBlob);
                    }, 'image/jpeg');
                };
                img.onerror = reject;
                const reader = new FileReader();
                reader.onloadend = () => img.src = reader.result as string;
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            }));
    }


    function performImageDeletion(shouldDelete: boolean) {
        setIsShowConfirmDeleteImage(false);
        if (!shouldDelete) return;

        Promise.resolve(removeImage(props.designImage).then((_) => {
            let currImages = props.removeImageFromList(props.designImage);
            if (currImages && currImages.length > 0) {
                props.setDesignImage(currImages[0]);
            } else {
                props.setDesignImage(null);
            }
            setImageMaskState(ImageMaskState.Select);
        }));
    }

    function handleUndo() {
        if (!props.designImage) return;
        if (!props.designImage.previous) return;

        let key = props.designImage.previous.key;
        let prev = props.designImage.previous;
        Promise.resolve(updateImage(dataURItoBlob(prev.src), key))
            .then((img) => {
                props.setDesignImage(img);
                props.replaceImageInList(img);
            });
    }

    function downloadImage(addWatermark = false) {
        if (!props.designImage) return;

        const imageSrc = props.designImage.src;
        if (!imageSrc) return;

        if (!addWatermark) {
            let uuid = Math.random().toString(36).substring(4);
            saveAs(props.designImage.src, `${props.designImage?.key}_${uuid}.png`);
            return;
        }

        const watermarkText = I18n.get("host") || "TheHomeDecorAI.com"; // Replace with your watermark text

        // Fetch the image
        fetch(imageSrc, {
            headers: {
                'Cache-Control': 'no-cache'
            }
        })
            .then(response => response.blob()) // Convert the response to a blob
            .then(blob => {
                const image = new Image();
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                image.onload = () => {
                    canvas.width = image.width;
                    canvas.height = image.height;

                    if (!ctx) return;

                    // Draw the main image
                    ctx.drawImage(image, 0, 0);

                    // Set the font to measure text width and height
                    ctx.font = '20px Arial';

                    // Measure text
                    const textMetrics = ctx.measureText(watermarkText);
                    const textWidth = textMetrics.width;
                    const textHeight = 20; // Font size as height approximation

                    // Calculate the position and size of the gradient rectangle
                    const padding = 10;
                    const rectWidth = textWidth + padding * 2;
                    const rectHeight = textHeight + padding * 2;
                    const rectX = canvas.width - rectWidth - padding;
                    const rectY = padding; // Adjusted for top right

                    // Create the gradient for the rectangle border
                    const gradient = ctx.createLinearGradient(rectX, rectY, rectX + rectWidth, rectY);
                    // Define the 6-color gradient
                    gradient.addColorStop(0, 'blue');
                    gradient.addColorStop(0.2, 'purple');
                    gradient.addColorStop(0.4, 'white');
                    gradient.addColorStop(0.6, 'orange');
                    gradient.addColorStop(0.8, 'purple');
                    gradient.addColorStop(1, 'blue');

                    // Draw the rectangle border with gradient
                    ctx.strokeStyle = gradient;
                    ctx.lineWidth = 2;
                    ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);

                    // Draw the text
                    ctx.fillStyle = 'black'; // Solid color for text
                    ctx.fillText(watermarkText, rectX + padding, rectY + padding + textHeight / 2 + 5);

                    // Convert canvas to image and download it
                    canvas.toBlob(blob => {
                        if (blob) { // Ensure blob is not null
                            let uuid = Math.random().toString(36).substring(4);
                            saveAs(blob, `${props.designImage?.key}_${uuid}.png`);
                        }
                    }, 'image/png');
                };

                // Set the image source to the blob URL
                image.src = URL.createObjectURL(blob);

                // Set the crossOrigin to anonymous to prevent CORS issues
                image.crossOrigin = 'anonymous';
            })
            .catch(error => {
                console.error('Error fetching the image:', error);
            });
    }

    return (
        <div>
            {
                isLightBoxEnabled &&
                <Lightbox
                    toolbarButtons={[
                        <Button color="secondary"
                            type="submit"
                            outline={true}
                            id="download"
                            onClick={() => downloadImage()}>
                            <FaDownload />
                        </Button>
                    ]}
                    mainSrc={props.designImage?.src ?? ""}
                    enableZoom={true}
                    onCloseRequest={() => {
                        setIsLightBoxEnabled(false);
                    }}
                />
            }
            <Card>
                <CardBody>
                    <Row>
                        <Row xl={2}>
                            {
                                <div className={`flex flex-col space-y-2 md:space-x-2 md:space-y-0 md:flex-row editorActions`}>
                                    <Button color={props.designImage && props.designImage.previous ? "primary" : "secondary"}
                                        type="submit"
                                        outline={true}
                                        size="sm"
                                        className={props.designImage && props.designImage.previous ? "enabled" : "disabled"}
                                        id="deselect"
                                        onClick={() => handleUndo()}>
                                        <FaUndo /> {I18n.get("undo")}
                                    </Button>
                                    <Button color="primary"
                                        type="submit"
                                        outline={true}
                                        size="sm"
                                        id="replace"
                                        className="enabled"
                                        onClick={() => {
                                            if (!props.designImage) {
                                                setAlert({
                                                    alertType: "warning",
                                                    alertContent: I18n.get("upload_image_or_inspire")
                                                });
                                                return;
                                            }
                                            setReplaceMode("image_to_image");
                                            setImageMaskState(ImageMaskState.Replace)
                                        }}>
                                        <FaMagic /> {I18n.get("redesign_my_image_button")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        outline={true}
                                        size="sm"
                                        id="inspireMe"
                                        onClick={(e: any) => {
                                            setReplaceMode("text_to_image");
                                            setMaskImageState(ImageMaskState.Inspire);
                                        }}>
                                        <MdLightbulb /> {I18n.get("inspire_me")}
                                    </Button>
                                    <Button color="primary"
                                        type="submit"
                                        outline={true}
                                        size="sm"
                                        id="download"
                                        className=""
                                        onClick={() => downloadImage()}>
                                        <MdDownload /> {I18n.get("Download")}
                                    </Button>
                                    <Button color="info"
                                        type="submit"
                                        outline={true}
                                        size="sm"
                                        id="download"
                                        className=""
                                        onClick={() => downloadImage(true)}>
                                        <MdDownload /> {I18n.get("Download_with_watermark")}
                                    </Button>
                                    <Button color={"warning"}
                                        type="submit"
                                        outline={true}
                                        id="download"
                                        className={props.designImage?.previous ? "enabled" : "disabled"}
                                        onClick={() => setShowImageDiff(!isShowImageDiff)}>
                                        <FaEye /> {isShowImageDiff ? I18n.get("turn_off") : I18n.get("before_after")}
                                    </Button>
                                    {imagePrompt &&
                                        <Button color="info"
                                            type="submit"
                                            outline={true}
                                            size="sm"
                                            id="reuse"
                                            className=""
                                            onClick={() => magicReplace(imagePrompt)}>
                                            <TbReplace /> {I18n.get("re_use")}
                                        </Button>
                                    }
                                    <Button color="danger"
                                        type="submit"
                                        outline={true}
                                        size="sm"
                                        id="delete"
                                        onClick={() => setIsShowConfirmDeleteImage(true)}>
                                        <FaTrash /> {I18n.get("delete")}
                                    </Button>
                                </div>
                            }
                        </Row>
                        <Row>
                            {!isCurrentImageMaskState(ImageMaskState.Replace) && props.designImage &&
                                <div>
                                    <canvas ref={canvasRef} id="hidden-canvas" className="invisible" />
                                    <div className="mt-4">
                                        {!isShowImageDiff && <ImageLoader designImage={props.designImage} />}
                                        <div>
                                            {isShowImageDiff && props.designImage.previous &&
                                                <div>
                                                    <ReactCompareImage
                                                        leftImage={props.designImage.src}
                                                        rightImage={props.designImage.previous.src}
                                                    />
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            }
                            {isCurrentImageMaskState(ImageMaskState.Replace) &&
                                <Form
                                    className="custom-form mt-4 pt-2"
                                    onSubmit={(e: any) => {
                                        e.preventDefault();
                                        return false;
                                    }}
                                >
                                    {
                                        replaceMode === "inpainting" &&
                                        <div className="mb-3">
                                            <Label className="form-label text-green-600">{I18n.get("replace_mode")}</Label>

                                            <select
                                                className="form-select form-select-sm"
                                                onChange={(e: any) => setMaskSource(e.target.value)}
                                            >
                                                <option value="MASK_IMAGE_BLACK">{I18n.get("replace_selected")}</option>
                                                <option value="MASK_IMAGE_WHITE">{I18n.get("replace_everything_else")}</option>
                                            </select>
                                        </div>
                                    }
                                    <HomeRedesignForm
                                        selectedValues={selectedValues}
                                        setSelectedValues={setSelectedValues}
                                        redesignImage={magicReplace}
                                        designType={DesignType.Redesign}
                                        generatedQuery={imagePrompt} />

                                </Form>
                            }
                            <Modal tabIndex={1} role="dialog" isOpen={isShowConfirmDeleteImage &&
                                props.designImage != undefined}>
                                <div className="modal-content">
                                    <ModalHeader>
                                        <h5 className="modal-title">{I18n.get("delete_image")}</h5>
                                    </ModalHeader>
                                    <ModalBody >
                                        <p>{I18n.get("delete_image_are_you_sure")}</p>
                                    </ModalBody>
                                    <ModalFooter >
                                        <button type="button" className="btn btn-secondary" onClick={() => performImageDeletion(false)}>
                                            {I18n.get("no")}
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => performImageDeletion(true)}>
                                            {I18n.get("yes")}
                                        </button>
                                    </ModalFooter>
                                </div>
                            </Modal>
                        </Row>
                    </Row>

                </CardBody >
                <CardFooter>
                    <Row>
                        <div className="flex flex-col m-auto p-auto">
                            <div className="flex overflow-x-scroll hide-scroll-bar">
                                <div className="flex flex-nowrap mt-0 mb-4 space-x-3">
                                    {
                                        props.currentImages &&
                                        props.currentImages.map((f: any, i: number) => {
                                            return (
                                                <a className="inline-block" href="#" key={i + "-image-file"}>
                                                    <img
                                                        src={f.src}
                                                        className={(selectedImage === i ? selectedThumbnailClass : unselectedThumbnailClass) + " hover:border-slate-800"}
                                                        height="140"
                                                        alt=""
                                                        onClick={(e) => changeSelectedImage(e, i)}
                                                    />
                                                </a>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Row>
                </CardFooter>
            </Card >
        </div >
    );
}
