import { I18n } from 'aws-amplify';
import React from 'react';
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem } from 'reactstrap';

interface SelectedSummaryProps {
    selectedSpaceType: string;
    selectedSpecificSpaceType: string;
    selectedStyle: string;
    selectedEmphasis: string;
    selectedWhatToEmphasize: string;
    detailOptionInput: string;
    selectedColorScheme: string;
    selectedBudget: string;
}

const SelectedSummary: React.FC<SelectedSummaryProps> = ({
    selectedSpaceType,
    selectedSpecificSpaceType,
    selectedStyle,
    selectedEmphasis,
    selectedWhatToEmphasize,
    detailOptionInput,
    selectedColorScheme,
    selectedBudget,
}) => {
    return (
        <Card>
            <CardBody>
                <CardTitle tag="h5">{I18n.get('Selected Options Summary')}</CardTitle>
                <ListGroup>
                    {selectedSpaceType && (
                        <ListGroupItem>
                            <strong>{I18n.get('Space Type:')}</strong> {selectedSpaceType}
                        </ListGroupItem>
                    )}
                    {selectedSpecificSpaceType && (
                        <ListGroupItem>
                            <strong>{I18n.get('Specific Space:')}</strong> {selectedSpecificSpaceType}
                        </ListGroupItem>
                    )}
                    {selectedStyle && (
                        <ListGroupItem>
                            <strong>{I18n.get('Style:')}</strong> {selectedStyle}
                        </ListGroupItem>
                    )}
                    {selectedEmphasis && (
                        <ListGroupItem>
                            <strong>{I18n.get('Emphasis:')}</strong> {selectedEmphasis}
                        </ListGroupItem>
                    )}
                    {selectedWhatToEmphasize && (
                        <ListGroupItem>
                            <strong>{I18n.get('What to Emphasize:')}</strong> {selectedWhatToEmphasize}
                        </ListGroupItem>
                    )}
                    {detailOptionInput && (
                        <ListGroupItem>
                            <strong>{I18n.get('Detail Option:')}</strong> {detailOptionInput}
                        </ListGroupItem>
                    )}
                    {selectedColorScheme && (
                        <ListGroupItem>
                            <strong>{I18n.get('Color Scheme:')}</strong> {selectedColorScheme}
                        </ListGroupItem>
                    )}
                    {selectedBudget && (
                        <ListGroupItem>
                            <strong>{I18n.get('Budget:')}</strong> {selectedBudget}
                        </ListGroupItem>
                    )}
                </ListGroup>
            </CardBody>
        </Card>
    );
};

export default SelectedSummary;
