export const decorFormDataEN = {
    "spaces": ["interior", "exterior"],
    "spaceTypes": {
        "interior": [
            "Living Room",
            "Bedroom",
            "Kitchen",
            "Bathroom",
            "Dining Room",
            "Home Office",
            "Nursery",
            "Home Theater",
            "Game Room",
            "Walk-In Closet",
            "Home Gym",
            "Study/Library",
            "Mudroom",
            "Sunroom",
            "Walls",
            "Bar Area",
            "Walk-In Pantry",
            "Laundry Room",
            "Guest Room",
            "Attic Space",
            "Wine Cellar"
        ],
        "exterior": [
            "Front Yard",
            "Backyard",
            "Facade",
            "Patio",
            "Walls",
            "Garden",
            "Porch",
            "Balcony",
            "Pool Area",
            "Outdoor Kitchen",
            "Roof Deck",
            "Courtyard",
            "Rooftop Garden",
            "Driveway",
            "Playground Area",
            "Outdoor Barbecue Area",
            "Sports Court",
            "Shed/Storage Area",
            "Dog Run",
            "Greenhouse",
            "Carport",
            "Boat Dock/Pier"
        ]
    },
    "styles": [
        "Modern",
        "Traditional",
        "Minimalist",
        "Industrial",
        "Scandinavian",
        "Mediterranean",
        "Bohemian",
        "Coastal",
        "Rustic",
        "Vintage"
    ],
    "emphasisElements": {
        "interior": [
            {
                "name": "Color",
                "detailedSelectionOptions": ["Wall Color", "Furniture Color", "Accent Color"]
            },
            {
                "name": "Walls",
                "detailedSelectionOptions": ["Plants", "Color", "Lighting"]
            },
            {
                "name": "Furniture",
                "detailedSelectionOptions": ["Type", "Material", "Color"]
            },
            {
                "name": "Lighting",
                "detailedSelectionOptions": ["Type", "Fixture Style", "Brightness"]
            },
            {
                "name": "Artwork",
                "detailedSelectionOptions": ["Art Style", "Size", "Color"]
            },
            {
                "name": "Carpets/Rugs",
                "detailedSelectionOptions": ["Texture", "Color", "Size", "Pattern", "Material"]
            },
            {
                "name": "Wall Decor",
                "detailedSelectionOptions": ["Paintings", "Mirrors", "Wallpapers", "Shelves"]
            },
            {
                "name": "Plants",
                "detailedSelectionOptions": ["Indoor Plants", "Planters", "Plant Colors"]
            },
            {
                "name": "Textures",
                "detailedSelectionOptions": ["Fabric", "Stone", "Wood", "Metal", "Glass"]
            },
            {
                "name": "Windows",
                "detailedSelectionOptions": ["Curtains", "Blinds", "Window Tint", "Window Frame Color"]
            },
            {
                "name": "Fireplace",
                "detailedSelectionOptions": ["Type", "Mantel Style", "Fireplace Finish"]
            },
            {
                "name": "Bookshelves/Bookcases",
                "detailedSelectionOptions": ["Design", "Material", "Color"]
            },
            {
                "name": "Home Entertainment Center",
                "detailedSelectionOptions": ["TV Size", "Media Console Design", "Speaker System"]
            },
            {
                "name": "Built-in Fireplace",
                "detailedSelectionOptions": ["Design", "Material", "Color"]
            },
            {
                "name": "Indoor Water Feature",
                "detailedSelectionOptions": ["Fountain", "Wall Waterfall", "Indoor Pond"]
            },
            {
                "name": "Statement Ceiling",
                "detailedSelectionOptions": ["Ceiling Design", "Chandelier", "Ceiling Color"]
            },
            {
                "name": "Window Seat",
                "detailedSelectionOptions": ["Window Cushion", "Pillows", "Window Covering"]
            },
            {
                "name": "Statement Wall",
                "detailedSelectionOptions": ["Accent Wall Color", "Wallpaper", "Texture"]
            },
            {
                "name": "Home Bar",
                "detailedSelectionOptions": ["Bar Counter Style", "Bar Stools", "Bar Accessories"]
            },
            {
                "name": "Home Office Workspace",
                "detailedSelectionOptions": ["Desk Style", "Chair", "Shelving"]
            },
            {
                "name": "Hidden Storage Solutions",
                "detailedSelectionOptions": ["Built-in Cabinets", "Under Stair Storage", "Closet Organizers"]
            }
        ],
        "exterior": [
            {
                "name": "Landscaping",
                "detailedSelectionOptions": ["Plants", "Hardscape", "Lighting", "Water Features"]
            },
            {
                "name": "Walls",
                "detailedSelectionOptions": ["Plants", "Color", "Lighting"]
            },
            {
                "name": "Outdoor Furniture",
                "detailedSelectionOptions": ["Type", "Material", "Color"]
            },
            {
                "name": "Lighting",
                "detailedSelectionOptions": ["Type", "Fixture Style", "Brightness"]
            },
            {
                "name": "Facade",
                "detailedSelectionOptions": ["Type", "Color"]
            },
            {
                "name": "Water Features",
                "detailedSelectionOptions": ["Fountain", "Pond", "Waterfall"]
            },
            {
                "name": "Pergola/Gazebo",
                "detailedSelectionOptions": ["Material", "Size", "Color"]
            },
            {
                "name": "Garden Beds",
                "detailedSelectionOptions": ["Plant Selection", "Bed Design", "Mulch/Gravel"]
            },
            {
                "name": "Pathways",
                "detailedSelectionOptions": ["Path Material", "Path Shape", "Path Lighting"]
            },
            {
                "name": "Decking/Patio",
                "detailedSelectionOptions": ["Deck Material", "Patio Pavers", "Deck/Patio Color"]
            },
            {
                "name": "Pool Area",
                "detailedSelectionOptions": ["Pool Type", "Pool Decking", "Pool Lighting"]
            },
            {
                "name": "Fencing",
                "detailedSelectionOptions": ["Fence Type", "Fence Height", "Fence Color"]
            },
            {
                "name": "Outdoor Kitchen Island",
                "detailedSelectionOptions": ["Island Style", "Countertop Material", "Outdoor Appliances"]
            },
            {
                "name": "Fire Pit/Fireplace",
                "detailedSelectionOptions": ["Fire Pit Design", "Fireplace Type", "Fire Pit/Fireplace Material"]
            },
            {
                "name": "Swimming Pool",
                "detailedSelectionOptions": ["Pool Shape", "Pool Size", "Pool Features"]
            },
            {
                "name": "Outdoor Lounge Area",
                "detailedSelectionOptions": ["Lounge Furniture", "Outdoor Rugs", "Outdoor Heating"]
            },
            {
                "name": "Garden Pathways",
                "detailedSelectionOptions": ["Path Material", "Path Shape", "Path Lighting"]
            },
            {
                "name": "Deck/Patio Cover",
                "detailedSelectionOptions": ["Cover Type", "Cover Material", "Color"]
            },
            {
                "name": "Outdoor Shower",
                "detailedSelectionOptions": ["Shower Type", "Shower Material", "Shower Accessories"]
            },
            {
                "name": "Pond or Water Feature",
                "detailedSelectionOptions": ["Pond Size", "Water Feature Type", "Water Feature Material"]
            },
            {
                "name": "Trellis or Arbor",
                "detailedSelectionOptions": ["Trellis/Arbor Style", "Material", "Color"]
            },
            {
                "name": "Custom Outdoor Furniture",
                "detailedSelectionOptions": ["Furniture Type", "Material", "Cushion Colors"]
            }
        ]
    },
    "colorSchemes": [
        "Neutral",
        "Vibrant",
        "Monochrome",
        "Pastel",
        "Earthy Tones",
        "Bold Contrasts",
        "Cool Blues",
        "Warm Reds",
        "Nature-Inspired",
        "Black and White"
    ],
    "budgetConstraints": [
        "Low Budget",
        "Moderate Budget",
        "High Budget",
        "No Budget Constraints"
    ],
    "materialCategories": ["Flooring", "Furniture", "Wall Coverings", "Countertops", "Cabinetry"],
    "materials": {
        "Flooring": ["Hardwood", "Tile", "Carpet", "Vinyl", "Laminate"],
        "Furniture": ["Wood", "Metal", "Glass", "Leather", "Fabric"],
        "Wall Coverings": ["Paint", "Wallpaper", "Tile", "Paneling", "Stone"],
        "Countertops": ["Granite", "Quartz", "Marble", "Laminate", "Concrete"],
        "Cabinetry": ["Wood", "Melamine", "Glass", "Stainless Steel", "Laminate"]
    }
};
