import React from "react";
import { Navigate } from "react-router-dom";

//designhub
import DesignHub from "../pages/DesignHub";

//workspace
import Workspace from "../pages/Workspace";

// billing
import ThankYou from "../pages/Subscriptions/thank_you";
import OAuthHandler from "../helpers/oauth_helper";
import StoreFront from "../pages/StoreFront";

//Authentication pages

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  // workspaces
  { path: "/workspaces", component: <Workspace /> },

  // StoreFront
  { path: "/storefront", component: <StoreFront /> },

  // design hub
  { path: "/designhub/:workspaceId", component: <DesignHub /> },

  // thank-you
  { path: "/thank-you/", component: <ThankYou /> },
  { path: "/thank-you", component: <ThankYou /> },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/workspaces/" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/oauth", component: <OAuthHandler /> },
];

export { userRoutes, authRoutes };
