import React, { useEffect } from "react";

import { Routes, Route } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import '@aws-amplify/ui-react/styles.css';
import { View, useTheme, withAuthenticator } from "@aws-amplify/ui-react";
import { Auth, Hub, I18n } from "aws-amplify";

const listener = (data: any) => {
    switch (data?.payload?.event) {
        case 'workspaces_loaded':
            hideSplashScreen();
            break;
        case 'designHub_loaded':
            hideSplashScreen();
            break;
        case 'signInPage_Loaded':
            hideSplashScreen();
            break;
        default:
            break;
    }
};

Hub.listen('app_events', listener);

let components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <h2 className="text-8xl">🪴</h2>
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <p className="text-slate-900">
                    TheHomeDecorAI. &copy; {I18n.get("copyright_reserved")}
                </p>
            </View>
        );
    },

    SignIn: {
        Header() {
            Hub.dispatch("app_events", {
                event: "signInPage_Loaded",
            });
            return (<></>
            );
        },
    },

    SignUp: {
        Header() {
            Hub.dispatch("app_events", {
                event: "signInPage_Loaded",
            });
            return (<></>
            );
        }
    }
};

function showSplashScreen() {
    let splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
        splashScreen.style.display = 'flex';
    }
}

function hideSplashScreen() {
    let splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
        splashScreen.style.display = 'none';
    }
}


const App = (props: any) => {
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(_ => {
                hideSplashScreen();
            })
            .catch(() => {
                hideSplashScreen();
            });
    }, []);



    const { layoutType } = useSelector((state: any) => ({
        layoutType: state.Layout.layoutType,
    }));

    function getLayout() {
        let layoutCls: Object = VerticalLayout;
        switch (layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    }

    const Layout: any = getLayout();
    return (
        <React.Fragment>
            <Routes>
                {authRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                        key={idx}
                    />
                ))}

                {userRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            <Layout>{route.component}</Layout>
                        }
                        key={idx}
                    />
                ))}
            </Routes>
        </React.Fragment>
    );
};

export default withAuthenticator(App, {
    components: components,
});
