
import { createContext } from "react";
import { DesignImage, modelInputProps, modelScaleProps } from "../../helpers/Interfaces";
import { ImageMaskState } from "../../../helpers/Interfaces";
import { Alert, AlertProps } from "reactstrap";
import { Workspace } from "../../../helpers/backend_interfaces";

interface contextProps {
    clicks: [
        clicks: modelInputProps[] | null,
        setClicks: (e: modelInputProps[] | null) => void
    ];
    image: [
        image: HTMLImageElement | undefined,
        setImage: (e: HTMLImageElement | undefined) => void
    ];
    previewImage: [
        image: DesignImage | undefined,
        setPreviewImage: (e: DesignImage | undefined) => void
    ];
    maskImageState: [
        maskImageState: ImageMaskState | undefined,
        setMaskImageState: (e: ImageMaskState) => void
    ];
    alert: [
        alert: AlertProps | null,
        setAlert: (e: AlertProps) => void
    ];
    workspaces: [
        workspaces: Workspace[] | [],
        setWorkspaces: (e: Workspace[]) => void
    ];
    currentWorkspace: [
        currentWorkspace: Workspace | undefined,
        setCurrentWorkspace: (e: Workspace) => void
    ];
    isLoading: [
        isLoading: boolean | false,
        setIsLoading: (e: boolean) => void
    ];
    replaceMode: [
        replaceMode: string,
        setReplaceMode: (e: string) => void
    ];
}

const DesignHubContext = createContext<contextProps | null>(null);

export default DesignHubContext;
