import { I18n } from "aws-amplify";
import React, { useContext } from "react";
import Dropzone from "react-dropzone";
import { withTranslation } from "react-i18next";
import { LuUpload } from "react-icons/lu";
import { Form, Spinner } from "reactstrap";

const ImageUpload = (props: any) => {
    return (
        <div>
            {props.isUploading &&
                <Spinner className="ms-2 align-center" color="primary" />
            }
            {!props.isUploading &&
                < Form >
                    <Dropzone
                        maxFiles={5}
                        onDrop={acceptedFiles => {
                            props.handleAcceptedFiles(acceptedFiles);
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                                <div
                                    className="text-base dz-message needsclick mt-2"
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <div className="mb-3">
                                        <LuUpload />
                                    </div>
                                    <p className="text-muted">{I18n.get("upload_image_instructions")}</p>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </Form>
            }
        </div>
    );
}

export default withTranslation()(ImageUpload);
