import { API, Auth } from "aws-amplify";
import { ApiResponse, DesignImage, NotEnougCreditsError, SubscriptionRequiredError } from "../../../components/helpers/Interfaces";

let API_NAME = 'HomeDecorApi-Prod';
if (typeof window !== 'undefined') {
    console.log(location.host);
    // if ("localhost:8081" === location.host || location.host === "www.beta-app.thehomedecorai.com" ||
    //     location.host === "beta-app.thehomedecorai.com") {
    //     API_NAME = 'HomeDecorApi-Beta';
    // }
}

export interface SamEmbeddingResponse {
    embeddingUrl: string
}

export const getSamEmbeddings = async (imageDataUrl: string): Promise<ApiResponse<ArrayBuffer>> => {
    const payload = {
        'image': imageDataUrl,
        'mask_image': "",
        'model': 'sam',
    };
    let response;
    try {
        response = await invokeEndpoint(payload);
    } catch (error) {
        throw error;
    }
    return {
        creditsRemaining: response.creditsRemaining,
        output: decodeBase64ToBytes(response.embeddings)
    }
}

const invokeEndpoint = async function (payload: any): Promise<any> {
    const path = '/invoke';
    const myInit = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        }, // OPTIONAL
        body: payload, // replace this with attributes you need
    };

    return await API.post(API_NAME, path, myInit)
        .catch((error) => {
            console.error(error, error.response, error.response.status);
            let hasResponse = error && error.response;
            if (hasResponse && error.response.status === 402) {
                throw new SubscriptionRequiredError("Subscription required");
            }
            else if (hasResponse && error.response.status === 429) {
                throw new NotEnougCreditsError("Not enough credits to complete the request");
            }
            else {
                throw error;
            }
        });
}

function decodeBase64ToBytes(base64: string): ArrayBuffer {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    return bytes.buffer;
}


export const eraseFromImage = async (initImageBase64?: string, maskImageBase64?: string): Promise<ApiResponse<DesignImage> | undefined> => {
    if (!initImageBase64 || !maskImageBase64) return undefined;

    try {
        const payload = {
            'image': initImageBase64,
            'mask_image': maskImageBase64,
            'model': 'lama'
        };

        let response;
        try {
            response = await invokeEndpoint(payload);
        } catch (error) {
            throw error;
        }

        if (!response || !response.imageUri) {
            throw new Error('Unexpected response from invokeEndpoint');
        }

        return {
            creditsRemaining: response.creditsRemaining,
            output: {
                src: response.imageUri,
                key: "",
                lastModified: new Date(),
            }
        };
    } catch (error) {
        console.error('Error in eraseFromImage:', error);
        throw error; // Re-throw the error to allow the caller to handle it
    }
}

export const initInvokeEndpoint = async (): Promise<any> => {
    const payload = {
        'image': "NONE",
        'mask_image': "NONE",
        'model': 'init'
    };
    let response;
    try {
        response = await invokeEndpoint(payload);
    } catch (error) {
        throw error;
    }
    return response;
}


export const invokeSD2 = async (payload: {}, key: string): Promise<ApiResponse<DesignImage> | undefined> => {
    const path = '/sd2';
    const myInit = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
        }, // OPTIONAL
        body: payload, // replace this with attributes you need
    };

    let response = await API.post(API_NAME, path, myInit)
        .catch((error) => {
            console.error(error);
            return undefined;
        });
    return {
        creditsRemaining: response.creditsRemaining,
        output: {
            src: `data:image/*;base64,${response.generated_images[0]}`,
            key: key,
            lastModified: new Date(),
        }
    }
}
