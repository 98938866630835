import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Workspace } from "../../helpers/backend_interfaces";
import { MdFolder } from "react-icons/md";
import React, { useContext, useState } from "react";
import DesignHubContext from "../../components/hooks/DesignHub/createContext";
import { Link, useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { deleteWorkspaceFolder } from "../../services/aws/s3/aws_s3_helper";
import { I18n } from "aws-amplify";

export const WorkspaceFolder = (workspace: Workspace) => {

    const {
        currentWorkspace: [currentWorkspace, setCurrentWorkspace],
        workspaces: [workspaces, setWorkspaces]
    } = useContext(DesignHubContext)!;

    const [isShowConfirmDeleteWorkspace, setIsShowConfirmDeleteWorkspace] = useState<boolean>(false);

    const navigate = useNavigate();

    function handleClick(e: any) {
        e.preventDefault();
        setCurrentWorkspace(workspace);
        navigate(`/designhub/${workspace.id}`);
    }

    function performWorkspaceDeletion(id: string, shouldDelete: boolean) {
        setIsShowConfirmDeleteWorkspace(false);
        if (!shouldDelete) return;

        Promise.resolve(deleteWorkspaceFolder(id)).then((deleted) => {
            if (deleted) {
                // Remove from list of workspaces
                let newWorkspaces: Workspace[] = [];
                workspaces.forEach(w => {
                    if (w.id !== id) {
                        newWorkspaces.push(w);
                    }
                });
                setWorkspaces(newWorkspaces);
            }
        });
    }

    return (
        <div>
            <a className={`border border-slate-500 workspaceCard opacity-98 hover:opacity-80 active:opacity-`}
                key={workspace.id}
                onClick={handleClick}
            >
                <div className="flex items-center flex-col">
                    <MdFolder
                        color={workspace.color}
                        size={150}
                        title={`${workspace.name} workspace`} />
                    <p className="text-lg">
                        {workspace.name || I18n.get('untitled')}
                    </p>

                </div>
            </a>
            <div className="flex items-center flex-col">
                <Button color="danger"
                    type="submit"
                    outline={true}
                    size="sm"
                    id="delete"
                    onClick={() => setIsShowConfirmDeleteWorkspace(true)}>
                    <FaTrash /> {I18n.get("delete")}
                </Button>
            </div>
            <Modal tabIndex={1} role="dialog" isOpen={isShowConfirmDeleteWorkspace}>
                <div className="modal-content">
                    <ModalHeader>
                        <h5 className="modal-title">{I18n.get("delete_workspace")}</h5>
                    </ModalHeader>
                    <ModalBody >
                        <p>{I18n.get("delete_workspace_are_you_sure")}</p>
                    </ModalBody>
                    <ModalFooter >
                        <button type="button" className="btn btn-secondary" onClick={() =>
                            performWorkspaceDeletion(workspace.id, false)
                        }>
                            {I18n.get("no")}
                        </button>
                        <button type="button" className="btn btn-danger" onClick={() =>
                            performWorkspaceDeletion(workspace.id, true)
                        }>
                            {I18n.get("yes")}
                        </button>
                    </ModalFooter>
                </div>
            </Modal>
        </div>

    )
}
