// src/components/SplashScreen.tsx
import React from 'react';
import './../assets/scss/splash_screen.css';  // Importing CSS for styling

const SplashScreen: React.FC = () => {
    return (
        <div className="splash-screen">
            <h1 className="text-16xl">🪴</h1>
            <h1 className="text-8xl">Loading...</h1>
        </div>
    );
};

export default SplashScreen;
