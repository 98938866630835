import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, AlertProps, Alert, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DesignHubContext from "../../components/hooks/DesignHub/createContext";
import { DesignImage, NotEnougCreditsError, SubscriptionRequiredError } from "../../components/helpers/Interfaces";
import { SelectedFiles } from "./selectedFiles";
import { Editor } from "./Editor/editor";
import ImageUpload from "./Editor/imageUpload";
import { dataURItoBlob } from "../../helpers/maskUtils";
/* @ts-ignore */
import { ImageFolderType, getWorkspaceFolder, getWorkspaceImages, uploadImage } from "../../services/aws/s3/aws_s3_helper";
import { Link, useParams } from "react-router-dom";
import { Workspaces } from "../Workspace/workspaces";
import { FaRedo } from "react-icons/fa";
import { initInvokeEndpoint } from "../../services/aws/sagemaker/aws_sagemaker_helper";
import { ImageMaskState } from "../../helpers/Interfaces";
import Paywall from "../Subscriptions/Paywall";
import { Workspace } from "../../helpers/backend_interfaces";
import { I18n } from "aws-amplify";
import { isCurrentImageMaskState } from "../../components/helpers/maskUtils";
import { InspireMeForm } from "../../components/Common/InspireMeForm";

const DesignHub = (props: any) => {
  document.title = "Home Decore AI | Your Future Home Awaits";
  const { workspaceId } = useParams();

  const {
    alert: [alert,],
    isLoading: [, setIsLoading],
    previewImage: [previewImage,],
    maskImageState: [maskImageState,],
    currentWorkspace: [, setCurrentWorkspace],
  } = useContext(DesignHubContext)!;

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [currentImages, setCurrentImages] = useState<DesignImage[]>([]);
  const [designImage, setDesignImage] = useState<DesignImage | undefined>(undefined);
  const [currentPageAlert, setCurrentPageAlert] = useState<AlertProps | undefined>(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [isCurrentPageLoading,] = useState<boolean>(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(true);
  const [creditsRemaining, setCreditsRemaining] = useState<number>(-1);
  const [workspace, setWorkspace] = useState<Workspace>();
  const [, setWorkspaceCount] = useState<Number>(-1);

  const canvasRef = useRef<any>(null);

  useEffect(() => {
    if (!alert) return;
    setCurrentPageAlert(alert);
  }, [alert])

  useEffect(() => {
    Promise.resolve(getWorkspaceFolder(workspaceId || ""))
      .then((workspace?: Workspace) => {
        if (workspace) {
          setWorkspace(workspace);
          setCurrentWorkspace(workspace);
        }
      });
  }, [])

  useEffect(() => {
    setCurrentPageAlert({
      alertType: "warning",
      alertContent: I18n.get("use_own_or_inspire")
    })

    setDesignImage(undefined);
    setCurrentImages([]);

    // Get user subscription status
    Promise.resolve(initInvokeEndpoint())
      .then((response) => {
        setIsSubscribed(true);
        if (response && response.creditsRemaining) {
          setCreditsRemaining(Number(response.creditsRemaining));
        }
      })
      .catch((error) => {
        if (error instanceof SubscriptionRequiredError) {
          setIsSubscribed(false);
        } else if (error instanceof NotEnougCreditsError) {
          setIsSubscribed(true);
        }
      });

    getWorkspaceImages(workspace).then((images) => {
      if (images && images.length > 0) {
        setCurrentImages([...images]);
        setDesignImage(images[0]);
      }
    });
    setIsLoading(false);
  }, [workspace]);

  function roundToMultipleOf64(n: number): number {
    let newWidth = ((n + 32) >> 6) << 6;
    return Math.max(newWidth, 384);
  }

  function removeImageFromList(img: DesignImage): DesignImage[] {
    let newCurrentImages: DesignImage[] = [];
    currentImages.forEach((curr: any) => {
      if (curr.key !== img.key) {
        newCurrentImages.push(curr);
      }
    });
    setCurrentImages(newCurrentImages);
    return newCurrentImages;
  }

  async function replaceImageInList(img: DesignImage): Promise<DesignImage[]> {
    let newCurrentImages: DesignImage[] = [];
    currentImages.forEach((curr: any) => {
      if (curr.key === img.key) {
        newCurrentImages.push(img);
      } else {
        newCurrentImages.push(curr);
      }
    });
    setCurrentImages(newCurrentImages);
    return newCurrentImages;
  }

  async function addImageInList(img: DesignImage): Promise<DesignImage[]> {
    let newCurrentImages: DesignImage[] = [img, ...currentImages];
    setCurrentImages(newCurrentImages);
    return newCurrentImages;
  }

  function drawImageOnCanvas(img: any) {
    let maxWidth = 1344; // Set your desired width here
    let maxHeight = 768; // Set your desired height here

    if (img.height > img.width) {
      maxWidth = 768;
      maxHeight = 1344;
    }

    canvasRef.current.width = maxWidth;
    canvasRef.current.height = maxHeight;

    // Draw the image on the canvas with high quality
    canvasRef.current.getContext("2d").drawImage(img, 0, 0, maxWidth, maxHeight);
  }

  function getImageDataUrlFromCanvas(): string {
    const canvas = canvasRef.current;
    return canvas.toDataURL();
  }

  async function handleAcceptedFiles(files: any) {
    setSelectedFiles(files);

    // upload to s3
    files.map((file: any) => {
      setIsUploading(true);
      setIsLoading(true);
      // setMaskImageState(ImageMaskState.Loading);

      let img = new Image()
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        URL.revokeObjectURL(img.src);

        drawImageOnCanvas(img);

        // Convert canvas to a data URL with specified quality
        const resizedImageDataURL = canvasRef.current.toDataURL('image/jpeg', 1); // 0.9 sets the quality to 90%
        uploadImage(dataURItoBlob(resizedImageDataURL),
          file.name,
          ImageFolderType.Other,
          workspace).then((uploadedImage) => {

            setIsUploading(false);
            setIsLoading(false);

            // Push the response into the uploadedImages
            setCurrentImages([uploadedImage, ...currentImages]);
            setDesignImage(uploadedImage);

            setCurrentPageAlert({
              alertType: "warning",
              alertContent: I18n.get("image_uploaded")
            })
          })
          .catch((error) => {
            // Handle any errors that occur during the API call
            console.error('Error uploading image:', error);
          });;
      }
    });
  }

  let isShowCredits: boolean = workspace !== undefined && designImage !== undefined;

  return (
    <div className="page-content">
      <Container fluid>
        {<Paywall isSubscribed={isSubscribed} />}
        {isSubscribed &&
          <Row>
            <Col xs={12}>
              <Breadcrumbs title={I18n.get("home_decor_title")}
                breadcrumbItem={I18n.get("design_hub")}
                badgeColor={workspace?.color}
                creditsRemaining={creditsRemaining}
                showCredits={isShowCredits}
                badgeTitle={workspace?.name}>
                {workspace && <Link to="/workspaces">{I18n.get("switch_workspace")} <FaRedo /></Link>}
              </Breadcrumbs>
              {!workspace && <Workspaces title={"select_workspace"} setWorkspaceCount={setWorkspaceCount} />}

              {workspace &&
                <Row>
                  <Col xl={4} lg={4}>
                    <Row>
                      <Card>
                        <CardBody >
                          <div>
                            <div>
                              < canvas ref={canvasRef} id="hidden-image-upload-canvas" className="invisible" hidden />
                              <ImageUpload isUploading={isUploading} handleAcceptedFiles={handleAcceptedFiles} />
                              <SelectedFiles files={selectedFiles} />
                              <div className="imagePreview">
                                {
                                  previewImage &&
                                  <img
                                    src={previewImage.src}
                                    height="140"
                                    alt=""
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>
                  <Col xl={8} lg={8}>
                    {isCurrentPageLoading &&
                      <div className="absolute inset-0 flex justify-center items-center z-10">
                        <Spinner className="ms-2 absolute" color="primary" />
                      </div>
                    }
                    <Row>
                      {
                        currentPageAlert && currentPageAlert.alertType &&
                        <Alert color={currentPageAlert.alertType}
                          toggle={() => setCurrentPageAlert({})}
                          fade={true}
                          className="alert-outline">
                          {currentPageAlert.alertContent}
                        </Alert>
                      }
                    </Row>
                    <Row>
                      {isCurrentImageMaskState(ImageMaskState.Inspire, maskImageState) ?
                        <InspireMeForm
                          setCreditsRemaining={setCreditsRemaining}
                          setDesignImage={setDesignImage}
                          addImageInList={addImageInList} />
                        :
                        <Editor
                          designImage={designImage}
                          currentImages={currentImages}
                          setDesignImage={setDesignImage}
                          removeImageFromList={removeImageFromList}
                          replaceImageInList={replaceImageInList}
                          addImageInList={addImageInList}
                          setCreditsRemaining={setCreditsRemaining}
                          getImageDataUrlFromCanvas={getImageDataUrlFromCanvas}
                        />
                      }
                    </Row>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        }
      </Container >
    </div >
  );
};

export default withTranslation()(DesignHub);

DesignHub.propTypes = {
  t: PropTypes.any,
};
