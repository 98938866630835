import { I18n } from "aws-amplify";

export function showSystemError(setAlert: Function, setIsProcessing: Function) {
    setIsProcessing(false);
    setAlert({
        alertType: "danger",
        alertContent: I18n.get("error_processing")
    });
}

export function showFinishedProcessingMessage(setAlert: Function, setIsProcessing: Function) {
    setIsProcessing(false);
    setAlert({
        alertType: "info",
        alertContent: I18n.get("finished_processing_out_of_sync"),
    });
}

export function showRetryingMessage(setAlert: Function, currentRetry: number) {
    setAlert({
        alertType: "warning",
        alertContent: I18n.get("processing_taking_long_retry")
    });
}

export function showNonPaymentMessage(setAlert: Function) {
    setAlert({
        alertType: "danger",
        alertContent: I18n.get("need_more_credits"),
    });
}

export function showObjectNotSelectedWarning(setAlert: Function, operation: string) {
    let strKey = `not_selected_${operation}`;
    setAlert({
        alertType: "warning",
        alertContent: I18n.get(strKey)
    });
}
