import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

// Redux Store
import { showRightSidebarAction } from "../../store/actions";

//import component
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LightDark from "../CommonForBoth/Menus/LightDark";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";


//redux
import { useSelector, useDispatch } from "react-redux";
import { FaBars } from "react-icons/fa";

const Header = (props: any) => {
  const dispatch = useDispatch();
  const { layoutMode, showRightSidebar } = useSelector((state: any) => ({
    layoutMode: state.Layout.layoutMode,
    showRightSidebar: state.Layout.ShowRightSidebar
  }));

  const [search, setsearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);
  const [isClick, setClick] = useState<boolean>(true);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
    } else {
      body.classList.remove("sidebar-enable");
    }
  }

  useEffect(() => {
    var body = document.body;
    setTimeout(() => {
      body.classList.remove("sidebar-enable");
    }, 100);
  }, [])

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/workspaces" className="logo logo-dark">
                <span className="logo-sm">
                </span>
                <span className="logo-lg">
                  <span className="logo-txt">HomeDecorAI</span>
                </span>
              </Link>

              <Link to="/workspaces" className="logo logo-light">
                <span className="logo-sm">
                </span>
                <span className="logo-lg">
                  <span className="logo-txt">HomeDecorAI</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <FaBars />
            </button>
          </div>
          <div className="d-flex">

            {/* light / dark mode */}
            <LightDark
              layoutMode={layoutMode}
              onChangeLayoutMode={props.onChangeLayoutMode}
            />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
