import { Amplify, Auth, Storage } from "aws-amplify";
import { DesignImage } from "../../../components/helpers/Interfaces";
import { formatBytes } from "../../../helpers/image_helper";
import { Workspace } from "../../../helpers/backend_interfaces";
const { v4: uuidv4 } = require('uuid');
const AmazonS3URI = require('amazon-s3-uri')

const WORKSPACES_SUB_FOLDER = "workspaces";

export enum ImageFolderType {
    Mask = "masks",
    Other = "images",
}

enum AccessLevel {
    Public = "public",
    Protected = "protected",
    Private = "private",
}

interface ImageMetadata {
    key: string,
    lastModified: Date,
    eTag?: string
}

// Workspace operations
export const createWorkspaceFolder = async (name: string, color: string): Promise<Workspace> => {
    let id = uuidv4();
    const response = await Storage.put(`${WORKSPACES_SUB_FOLDER}/${id}/info.json`, {
        "name": name,
        "color": color,
        "id": id,
    }, {
        level: AccessLevel.Private,
        contentType: "application/json",
    });
    return {
        name,
        id,
        color
    }
}

export const deleteWorkspaceFolder = async (id: string): Promise<boolean> => {
    if (!id) return false;
    let deleteReq: any[] = [];
    Storage.list(`${WORKSPACES_SUB_FOLDER}/${id}`, { level: 'private' })
        .then(({ results }) => {
            results.forEach((result) => {
                deleteReq.push(Storage.remove(result.key || "", { level: 'private' }));
            })
        })
        .catch((err) => console.error(err));
    Promise.all(deleteReq)
    return true
}


export const getWorkspaceFolder = async (id: string): Promise<Workspace | undefined> => {
    if (!id) return undefined;

    let response = await Storage.get(`${WORKSPACES_SUB_FOLDER}/${id}/info.json`, {
        level: AccessLevel.Private, download: true,
        contentType: "application/json",
    });

    if (!response || !response.Body) return undefined;
    let body = await response.Body.text();
    let bodyAsJson = JSON.parse(body);

    return {
        name: bodyAsJson["name"],
        id: bodyAsJson["id"],
        color: bodyAsJson["color"],
    }
}

export const getWorkspaceFolders = async (): Promise<Workspace[]> => {
    const response = Storage.list(`${WORKSPACES_SUB_FOLDER}`, { level: AccessLevel.Private });

    let folderInfoRequests: any[] = [];
    await response.then((r) => {
        folderInfoRequests = r.results.map((res) => {
            if (!res.key) return;
            if (!res.key.endsWith("info.json")) return;
            return Storage.get(res.key, {
                level: AccessLevel.Private,
                download: true,
                contentType: "application/json",
            });
        });
    });

    let results: any = [];
    await Promise.all(folderInfoRequests).then((values) => {
        results = values;
    });

    results = results.filter((r: any) => r);
    let bodyResultsAsync = results.map((res: any) => {
        return res.Body.text();
    });

    await Promise.all(bodyResultsAsync).then((values) => results = values);
    let workspaces = results.map((res: any) => {
        let bodyAsJson = JSON.parse(res);

        return {
            name: bodyAsJson["name"],
            id: bodyAsJson["id"],
            color: bodyAsJson["color"],
        }
    });
    return workspaces;
}

export const getWorkspaceImages = async (workspace: Workspace | undefined): Promise<DesignImage[]> => {
    if (!workspace) return [];

    let images: DesignImage[] = [];

    let results: any = [];
    let metadata: ImageMetadata[] = [];
    await Storage.list(`${WORKSPACES_SUB_FOLDER}/${workspace.id}/${ImageFolderType.Other}`,
        {
            level: AccessLevel.Private,
            pageSize: 'ALL',

        }).then((res) => {
            if (res.results) {
                res.results.forEach((resp) => {
                    if (!resp.key) return;
                    resp.lastModified
                    metadata.push({
                        key: resp.key,
                        eTag: resp.eTag,
                        lastModified: resp.lastModified || new Date(),
                    });
                    results.push(
                        Storage.get(resp.key, { level: 'private' })
                    );
                })
            }
        }).catch((err) => console.error(err));

    let imageUrls: string[] = [];
    await Promise.all(results).then((values) => {
        imageUrls = values;
    });
    imageUrls.forEach((url, i) => {

        let designImage: DesignImage = {
            src: url,
            lastModified: metadata[i].lastModified,
            key: metadata[i].key,
            eTag: metadata[i].eTag,
            isProcessed: false,
        };
        images.push(designImage)
    })
    return images.sort((a, b) => b.lastModified.getTime() - a.lastModified.getTime());
}

// Image operations
export const uploadImage = async (imageData: any,
    imageFileName: string,
    imageFolderType: ImageFolderType,
    workspace?: Workspace): Promise<DesignImage> => {
    if (!workspace) return { src: "", key: "", lastModified: new Date() }

    let imageId = uuidv4();
    let fileName = `${imageId}_${imageFileName}`;
    const response = await Storage.put(`${WORKSPACES_SUB_FOLDER}/${workspace.id}/${imageFolderType}/${fileName}`,
        imageData, {
        level: AccessLevel.Private,
        contentType: "image/*",
    });
    // TODO: Check for errors on response
    return {
        src: await Storage.get(response.key, { level: 'private' }),
        key: response.key,
        lastModified: new Date(),
    }
}

export const removeImage = async (image?: DesignImage): Promise<boolean> => {
    if (!image) return false;
    await Storage.remove(image.key, { level: 'private' });
    return true;
}

export const updateImage = async (imageData: any, key: string): Promise<DesignImage> => {
    if (!imageData || !key) return { src: "", key: "", lastModified: new Date() }

    const response = await Storage.put(key,
        imageData, {
        level: AccessLevel.Private,
        contentType: "image/*",
    });
    // TODO: Check for errors on response
    return {
        src: await Storage.get(response.key, { level: 'private' }),
        key: response.key,
        isProcessed: true,
        lastModified: new Date(),
    }
}

// Embeddings
export const getEmbedding = async (embedding_key: string): Promise<string> => {
    return await Storage.get(embedding_key, { level: AccessLevel.Private });
}

// Public helpers
export const s3UrltoUri = (url?: string): string => {
    let uri: string = "";
    if (!url) return uri;

    try {
        const { _, bucket, key } = AmazonS3URI(url);
        uri = `s3://${bucket}/${key}`;
    } catch (err) {
        console.warn(`${url} is not a valid S3 url`)
    }
    return uri;
}
// Private helper
