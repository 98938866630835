import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";

export const SelectedFiles = (props: any) => {
    if (!props.files) {
        // TODO: Log error
        return <div></div>
    }
    return (<div className="dropzone-previews mt-3" id="file-previews">
        {props.files.map((f: any, i: number) => {
            return (
                <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                >
                    <div className="p-2">
                        <Row className="align-items-center">
                            <Col>
                                <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                >
                                    {f.name}
                                </Link>
                                <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Card>
            );
        })}
    </div>);
}
