import React, { useEffect, useState } from "react";
import { initInvokeEndpoint } from "../../services/aws/sagemaker/aws_sagemaker_helper";
import { NotEnougCreditsError, SubscriptionRequiredError } from "../../components/helpers/Interfaces";
import BeforeAfterImages from "./BeforeAfterImages";

const StoreFront = () => {
    const [isSubscribed, setIsSubscribed] = useState<boolean>(true);

    useEffect(() => {
        // Get user subscription status
        Promise.resolve(initInvokeEndpoint())
            .then((_) => {
                setIsSubscribed(true);
            })
            .catch((error) => {
                if (error instanceof SubscriptionRequiredError) {
                    setIsSubscribed(false);
                } else if (error instanceof NotEnougCreditsError) {
                    setIsSubscribed(true);
                }
            });
    }, []);

    return (
        <>
            {
                !isSubscribed &&
                <div className="container mx-auto p-4">
                    <BeforeAfterImages isSubscribed={isSubscribed} />
                </div>
            }
        </>
    );
}

export default StoreFront;
