// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/SplashScreen.css */
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2C302E;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it overlays everything else */
}

.text-16xl {
    font-size: 10rem;
    color: #fff;
}

.text-8xl {
    color: #fff;
    font-size: 5rem;
}
`, "",{"version":3,"sources":["webpack://./src/assets/scss/splash_screen.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,uCAAuC;AAC3C;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":["/* src/components/SplashScreen.css */\n.splash-screen {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #2C302E;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 9999;\n    /* Ensure it overlays everything else */\n}\n\n.text-16xl {\n    font-size: 10rem;\n    color: #fff;\n}\n\n.text-8xl {\n    color: #fff;\n    font-size: 5rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
