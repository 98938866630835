// Add the Firebase products that you want to use
class FirebaseAuthBackend {
  constructor(firebaseConfig: any) {

  }

  /**
   * Registers the user with given details
   */
  registerUser = (email: any, password: any) => {
    return new Promise((resolve, reject) => {

    })
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email: any, password: any) => {
    return new Promise((resolve, reject) => {
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email: any, password: any) => {
    return new Promise((resolve, reject) => {

    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = (email: any) => {
    return new Promise((resolve, reject) => {

    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {

    })
  }

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data: any, type: any) => {
    let credential: any = {}
    if (type === "google") {
    } else if (type === "facebook") {
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {

      } else {
        reject(this._handleError(Error))
      }
    })
  }

  addNewUserToFirestore = (user: any) => {
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
    }
    // collection.doc(firebase.auth().currentUser.uid).set(details)
    return { user, details }
  }

  setLoggeedInUser = (user: any) => {
    localStorage.setItem("authUser", JSON.stringify(user))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    const authUser: any = localStorage.getItem("authUser");
    if (!authUser) return null
    return JSON.parse(authUser)
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error: any) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend: any = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config: any) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)
  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
