
import React, { useState } from "react";
import { DesignImage, modelInputProps, modelScaleProps } from "../../helpers/Interfaces";
import DesignHubContext from "./createContext";
import { ImageMaskState } from "../../../helpers/Interfaces";
import { AlertProps } from "reactstrap";
import { Workspace } from "../../../helpers/backend_interfaces";

const DesignHubContextProvider = (props: {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}) => {
    const [clicks, setClicks] = useState<Array<modelInputProps> | null>(null);
    const [image, setImage] = useState<HTMLImageElement | undefined>(undefined);
    const [maskImageState, setMaskImageState] = useState<ImageMaskState | undefined>(ImageMaskState.Deselected);
    const [alert, setAlert] = useState<AlertProps | null>(null);
    const [workspaces, setWorkspaces] = useState<Workspace[] | []>([]);
    const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean | false>(true);
    const [previewImage, setPreviewImage] = useState<DesignImage | undefined>(undefined);
    const [replaceMode, setReplaceMode] = useState<string>("image_to_image");

    return (
        <DesignHubContext.Provider
            value={{
                clicks: [clicks, setClicks],
                image: [image, setImage],
                maskImageState: [maskImageState, setMaskImageState],
                alert: [alert, setAlert],
                workspaces: [workspaces, setWorkspaces],
                currentWorkspace: [currentWorkspace, setCurrentWorkspace],
                isLoading: [isLoading, setIsLoading],
                previewImage: [previewImage, setPreviewImage],
                replaceMode: [replaceMode, setReplaceMode]
            }}
        >
            {props.children}
        </DesignHubContext.Provider>
    );
};

export default DesignHubContextProvider;
