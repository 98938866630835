import React from "react";
import { storeFrontImages } from "./storeFrontData";
import { Button, Col, Row } from "reactstrap";
import { I18n } from "aws-amplify";
import Paywall from "../Subscriptions/Paywall";

export interface BeforeAfterImagesProps {
    isSubscribed: boolean;
}

const BeforeAfterImages = (props: BeforeAfterImagesProps) => {
    const [showPayWall, setShowPayWall] = React.useState<boolean>(false);

    function shuffleArray(array: any[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const shuffledImages = shuffleArray([...storeFrontImages]);
    return (
        <>
            {
                showPayWall && <Paywall hideLead={false} isSubscribed={props.isSubscribed} />
            }
            {!showPayWall &&
                <>
                    <Row>
                        <h2>
                            {I18n.get("what_that_app_can_do")}
                            <Button
                                color="primary"
                                className="m-2"
                                type="submit"
                                onClick={() => setShowPayWall(true)}>
                                {I18n.get("click_here_to_get_started")}
                            </Button>
                        </h2>
                    </Row>
                </>
            }
            {!showPayWall && shuffledImages.map((imagePair, index) => (
                <>
                    {index > 0 && index % 2 === 0 &&
                        <Row className="mb-4">
                            <Paywall hideLead={true} isSubscribed={props.isSubscribed} />
                        </Row>
                    }
                    <Row className="mb-4">
                        <Col md={6}>
                            <img src={imagePair.before} alt="Before" className="w-full" />
                            <span className="bg-red-600 absolute top-0 left-3 transform rotate-45 origin-top-left text-white text-lg px-4 py-2">
                                {I18n.get("before")}
                            </span>
                        </Col>
                        <Col md={6}>
                            <img src={imagePair.after} alt="After" className="w-full" />
                            <span className="bg-green-600 absolute top-0 left-3 transform rotate-45 origin-top-left text-white text-lg px-4 py-2">
                                {I18n.get("after")}
                            </span>
                        </Col>
                    </Row>
                </>
            ))
            }
        </>
    );
}

export default BeforeAfterImages;
