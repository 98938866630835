import React, { useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col, Alert } from 'reactstrap';
import SelectedSummary from './SelectedSummary';
import DesignHubContext from '../../../components/hooks/DesignHub/createContext';
import { ImageMaskState } from '../../../helpers/Interfaces';
import { I18n } from 'aws-amplify';
import { MdCancel } from 'react-icons/md';
import { decorFormDataES_KV } from '../../../data/form/decorFormDataES_KV';
import { FaMagic } from 'react-icons/fa';
import { TbReplace } from 'react-icons/tb';
import { decorFormDataEN } from '../../../data/form/decorFormData_EN';
import { decorFormDataES } from '../../../data/form/decorFormData_ES';

enum FormType {
    TextField,
    Dropdown
}

export enum DesignType {
    Inspire,
    Redesign
}

export interface HomeDesignFormProps {
    setSelectedValues: (e: any) => void;
    redesignImage: (prompt: string) => void;
    selectedValues: any;
    designType: DesignType;
    generatedQuery?: string;
}


const HomeRedesignForm = (props: HomeDesignFormProps) => {

    const {
        maskImageState: [, setMaskImageState],
        alert: [, setAlert],
    } = useContext(DesignHubContext)!;

    const [currentStep, setCurrentStep] = useState<number>(1);
    const [error, setError] = useState<string | null>(null); // Error message state

    const [isItemSelected, setIsItemSelected] = useState<boolean>(false);
    const [isPreviousButtonPressed, setIsPreviousButtonPressed] = useState<boolean>(false);
    const [options, setOptions] = useState<any | null>(null); // Store the options from JSON file

    const [isFreeForm, setIsFreeForm] = useState<boolean>(false);
    const [freeFormText, setFreeFormText] = useState<string>("");

    const lang = I18n.get('lang');

    useEffect(() => {
        if (lang === 'es') {
            setOptions(decorFormDataES);
        } else {
            setOptions(decorFormDataEN);

        }
    }, []);



    function cancelSelectedObjectReplacement(e: any) {
        setMaskImageState(ImageMaskState.Selected);
    }

    const handleNextStep = () => {
        if (isItemSelected) {
            setError(null); // Clear any previous errors
            setIsItemSelected(false);
            setCurrentStep((prevStep) => prevStep + 1);
        } else {
            setError(I18n.get('Please select an item.')); // Set an error message
        }
        setIsPreviousButtonPressed(false);
    };

    const handlePreviousStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
        setIsPreviousButtonPressed(true);
    };

    const getValueForDecor = (key: string) => {
        return decorFormDataES_KV[key] || key;
    }


    function redesignImage(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault(); // Prevent the default form submission

        if (!isFreeForm) {
            // Destructure selected values from the state
            const {
                selectedSpaceType,
                selectedSpecificSpaceType,
                selectedStyle,
                selectedEmphasis,
                selectedWhatToEmphasize,
                detailOptionInput,
                selectedColorScheme,
                selectedBudget,
            } = props.selectedValues;

            // Construct the query based on selected options
            const query = {
                spaceType: selectedSpaceType,
                exactSpace: selectedSpecificSpaceType,
                style: selectedStyle,
                emphasis: selectedEmphasis,
                whatToEmphasize: selectedWhatToEmphasize,
                detailOption: detailOptionInput,
                colorScheme: selectedColorScheme,
                budget: selectedBudget,
            };


            // Generate a text based on the selected options
            const generatedQuery = `Create a beautiful and high-quality ${getValueForDecor(query.spaceType)}
            design of a ${getValueForDecor(query.exactSpace)} with a ${getValueForDecor(query.style)} style.
            Pay extreme attention to ${getValueForDecor(query.emphasis)}, make the ${getValueForDecor(query.whatToEmphasize)}
            ${query.detailOption}. Utilize a ${getValueForDecor(query.colorScheme)} color scheme to make it
            visually appealing and ensure it fits within a ${getValueForDecor(query.budget)} budget.`

            props.redesignImage(generatedQuery);

        } else {
            setAlert({
                alertType: "warning",
                alertContent: I18n.get("invalid_prompt"),
            });
            return;
        }

    }

    // Function to generate form fields for each step
    const generateFormFields = (step: number, label: string, name: string, options: string[] | undefined, formType?: FormType) => {
        const action: string = isPreviousButtonPressed ? I18n.get('Change') : I18n.get('Select');
        if (FormType.TextField === formType) {
            return (
                <FormGroup>
                    <Label for={`${name}Input`}>{label}:</Label>
                    <Input
                        type="text"
                        name={name}
                        id={`${name}Input`}
                        value={props.selectedValues[name]}
                        required
                        onChange={(e) => {
                            props.setSelectedValues({ ...props.selectedValues, [name]: e.target.value });
                            setError(null);
                            setIsItemSelected(!!e.target.value); // Set isItemSelected based on input
                        }}
                    />
                </FormGroup>
            );
        }
        return (
            <FormGroup>
                <Label for={`${name}Select`}>{action} {label}:</Label>
                <Input
                    type="select"
                    name={name}
                    id={`${name}Select`}
                    required
                    onSelect={(e) => {
                        setError(null);
                        setIsItemSelected(true);
                    }}
                    onChange={(e) => {
                        props.setSelectedValues({ ...props.selectedValues, [name]: e.target.value });
                        setError(null);
                        setIsItemSelected(!!e.target.value); // Set isItemSelected based on selection
                    }}
                >
                    <option value="">{action} {label.toLowerCase()}</option>
                    {options?.map((item: string) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        );
    }

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return generateFormFields(1, 'Space Type', 'selectedSpaceType', spaceTypeOptions);
            case 2:
                if (props.selectedValues.selectedSpaceType === 'interior') {
                    return generateFormFields(2, I18n.get('Specific Interior Space'), 'selectedSpecificSpaceType', options?.spaceTypes.interior);
                } else if (props.selectedValues.selectedSpaceType === 'exterior') {
                    return generateFormFields(2, I18n.get('Specific Exterior Space'), 'selectedSpecificSpaceType', options?.spaceTypes.exterior);
                }
                break;
            case 3:
                return generateFormFields(3, I18n.get('Style'), 'selectedStyle', styleOptions);
            case 4:
                return generateFormFields(4, I18n.get('Item to Emphasize'), 'selectedEmphasis', emphasisOptions);
            case 5:
                return generateFormFields(5, I18n.get('What to Emphasize'), 'selectedWhatToEmphasize', whatToEmphasizeOptions);
            case 6:
                return generateFormFields(6, `${I18n.get("Write the")} ${props.selectedValues.selectedWhatToEmphasize}`, 'detailOptionInput',
                    [], FormType.TextField);
            case 7:
                return generateFormFields(7, I18n.get('Color Scheme'), 'selectedColorScheme', colorSchemeOptions);
            case 8:
                return generateFormFields(8, I18n.get('Budget Constraints'), 'selectedBudget', budgetOptions);
            default:
                return null;
        }
    };

    const spaceTypeOptions = options?.spaces;

    const styleOptions = options?.styles;

    const emphasisOptions = options?.emphasisElements[props.selectedValues.selectedSpaceType]?.map((element: any) => (
        element.name
    ));

    const whatToEmphasizeOptions = options?.emphasisElements[props.selectedValues.selectedSpaceType]
        ?.find((element: any) => element.name === props.selectedValues.selectedEmphasis)
        ?.detailedSelectionOptions.map((option: string) => (
            option
        ));

    const colorSchemeOptions = options?.colorSchemes;

    const budgetOptions = options?.budgetConstraints;

    const renderSelectedSummary = () => {
        return (
            <SelectedSummary
                selectedSpaceType={props.selectedValues.selectedSpaceType}
                selectedSpecificSpaceType={props.selectedValues.selectedSpecificSpaceType}
                selectedStyle={props.selectedValues.selectedStyle}
                selectedEmphasis={props.selectedValues.selectedEmphasis}
                selectedWhatToEmphasize={props.selectedValues.selectedWhatToEmphasize}
                detailOptionInput={props.selectedValues.detailOptionInput}
                selectedColorScheme={props.selectedValues.selectedColorScheme}
                selectedBudget={props.selectedValues.selectedBudget}
            />
        );
    };

    return (
        <>
            {error && !isItemSelected && (
                <Row>
                    <Col>
                        <Alert color="danger">{error}</Alert>
                    </Col>
                </Row>
            )}
            {isFreeForm &&
                <>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <Button primary color="secondary" onClick={() => setIsFreeForm(false)}>
                                {I18n.get("use_selection_form")}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <div className="mb-3">
                            <Label className="form-label"></Label>
                            <Input
                                type="textarea"
                                id="free-from-prompt-input"
                                className="form-control"
                                name="positivePrompt"
                                placeholder="Create a beautiful living room with christmas decorations."
                                onChange={(e) => setFreeFormText(e.target.value)}
                            />
                        </div>
                        <div className="float-right">
                            <Button
                                color="primary"
                                type="submit"
                                className='float-right'
                                onClick={(e) => props.redesignImage(freeFormText)}>
                                {props.designType === DesignType.Inspire ?
                                    <>
                                        {I18n.get("inspire_me")} <TbReplace />
                                    </> :
                                    <>
                                        {I18n.get("Redesign")} <FaMagic />
                                    </>
                                }
                            </Button>
                        </div>
                    </Row>
                </>
            }
            {!isFreeForm &&
                <>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <Button primary color="secondary" onClick={() => setIsFreeForm(true)}>
                                {I18n.get("free_form")}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="secondary"
                                type="submit"
                                className='float-right'
                                onClick={cancelSelectedObjectReplacement}>
                                {I18n.get("cancel")} <MdCancel />
                            </Button>
                        </Col>
                    </Row>
                    <Form>
                        {renderStepContent()}
                    </Form>
                    <Row>
                        <Col xl={6}>
                            <div className="float-right mt-3 space-x-4">
                                {currentStep > 1 && (
                                    <Button color="secondary" onClick={handlePreviousStep}>
                                        {I18n.get('Previous')}
                                    </Button>
                                )}
                                {currentStep < 8 && (
                                    <Button color="primary" onClick={handleNextStep}>
                                        {I18n.get('Next')}
                                    </Button>
                                )}
                                {currentStep === 8 && (
                                    <Button color="primary" type="submit" onClick={(e) => redesignImage(e)}>
                                        {props.designType === DesignType.Inspire ?
                                            <>
                                                {I18n.get("inspire_me")} <TbReplace />
                                            </> :
                                            <>
                                                {I18n.get("Redesign")} <FaMagic />
                                            </>
                                        }
                                    </Button>
                                )}
                            </div>
                        </Col>
                        <Col xl={6}>
                            <Row>
                                {renderSelectedSummary()}
                            </Row>
                        </Col>
                    </Row>
                </>
            }

        </>
    );
};

export default HomeRedesignForm;
