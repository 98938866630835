import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import React from "react";
import StripePricingTable from "../../components/Payments/stripePricingTable";
import { withTranslation } from "react-i18next";

const Paywall = (props: any) => {
    const [userId, setUserId] = useState<string>("");
    const [email, setEmail] = useState<string>("");


    useEffect(() => {
        // get authenticated userId
        Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
            if (userInfo && userInfo.attributes) {
                setUserId(userInfo.attributes.sub);
                setEmail(userInfo.attributes.email);
            }
        });
    }, []);

    return (<>
        {!props.isSubscribed &&
            <StripePricingTable
                userId={userId}
                email={email}
                hideLead={props.hideLead}
                lead={"paywall_lead"} />
        }
    </>);
}

export default withTranslation()(Paywall);
