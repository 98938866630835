import { AppDictionary } from "./app_types";

export const esAppDict: AppDictionary = {
    'lang': 'es',
    'before': 'Antes',
    'after': 'Después',
    'what_that_app_can_do': 'Desplázate hacia abajo y mira lo que la aplicación puede hacer',
    'click_here_to_get_started': '¡Haz clic aquí para empezar!',
    'title': 'Tu Futuro Hogar Te Espera | HomeDecorAI',
    'error_processing': 'Por favor, actualiza la página. Hubo un error procesando tu solicitud.',
    'detecting_objects': 'Detectando todos los objetos en la imagen...',
    'image_ready': 'Objetos de imagen procesados. Toque o haga clic en un objeto para seleccionarlo, luego toque o haga clic en eliminar para borrar.',
    'need_more_credits': 'Has agotado todos tus créditos para este mes. Si tu suscripción está activa, puedes contactar a thehomedecorai+ayuda@gmail.com para créditos adicionales',
    'system_taking_too_long': 'Nuestro sistema está tardando más de lo habitual. Reintentando...',
    'finished_object_detection': "Por favor, toque o haga clic en 'procesar los objetos de imagen' para poder usar eliminar.",
    'upload_image': 'Por favor sube una imagen',
    'upload_image_or_inspire': 'Por favor, suba una imagen o use inspirar para generar una',
    'use_own_or_inspire': 'Usa tu(s) propia(s) imagen(es) o utiliza la función "inspirame" para generar una',
    'select_workspace': 'Por favor selecciona un espacio de trabajo',
    'upload_image_instructions': 'Arrastra y suelta una imagen para subirla, también puedes hacer clic o tocar para subir',
    'design_hub': 'Centro de Diseño',
    'home_decor_title': 'HomeDecorAI',
    'workspaces': 'Mis Espacios de Trabajo',
    'manage_subscription': 'Administrar Mi Suscripción',
    'showing_available_wkspcs': 'Mostrar tus espacios de trabajo disponibles',
    'create_workspace': 'Crear Nuevo Espacio de Trabajo',
    'Menu': 'Menú',
    'designed_by': 'Diseñado y Desarrollado por',
    'Logout': 'Cerrar Sesión',
    'paywall_lead': '¡Conviértete en tu propio diseñador de interiores. ¡Suscríbete ahora para acceder exclusivamente a recursos de diseño premium y observa cómo sucede la magia!',
    'switch_workspace': 'Cambiar espacio de trabajo',
    'credits_remaining': 'Créditos restantes',
    'loading': 'Cargando...',
    'no_images_found': 'No se encontraron imágenes en el espacio de trabajo',
    'undo': 'Deshacer',
    'design_for_me_button': 'Diseñar para mí',
    'redesign_my_image_button': 'Rediseñar Mi Imagen',
    'deselect': 'Deseleccionar',
    'click_or_tap_to_allow_replace': 'Por favor toca o haz clic en procesar objetos de imagen para permitir reemplazar',
    'click_or_tap_to_allow_remove': "Por favor toca o haz clic en 'Procesar objetos de imagen' para permitir remover",
    'enhance': 'Mejorar',
    'process_image_objects': 'Procesar objetos de imagen',
    'delete': 'Eliminar',
    'replace_mode': 'Modo de Reemplazo:',
    'replace_selected': 'Reemplazar seleccionado',
    'replace_everything_else': 'Reemplazar Todo lo Demás',
    'finish_image_detect': 'Finalizado la detección de todos los objetos en la imagen',
    'before_after': 'Antes/Después',
    'remove': 'Remover',
    'yes': 'Sí',
    'no': 'No',
    'cancel': 'Cancelar',
    'replace': 'Reemplazar',
    'replace_with': 'Reemplazar con',
    'describe_redesign': "Describe tu visión para el diseño ideal de tu interior/exterior.",
    'describe_inspire': 'Imagina tu espacio habitable ideal. ¿Es moderno, rústico o algo diferente? Descríbelo con detalle.',
    'avoid_these_things': 'Evitar estas cosas',
    'positive_prompt_placeholder': 'Una sala de estar hermosa y moderna con bonitas flores y alfombra',
    'negative_prompt_placeholder': 'Feo, Deformado, Mala calidad',
    'delete_image': 'Eliminar imagen permanentemente',
    'delete_image_are_you_sure': '¿Estás seguro de que quieres eliminar la imagen?',
    'delete_workspace': 'Eliminar espacio de trabajo permanentemente',
    'delete_workspace_are_you_sure': '¿Estás seguro de que quieres eliminar el espacio de trabajo? Esto también eliminará TODAS las imágenes en el espacio de trabajo',
    'copyright_reserved': 'Todos los Derechos Reservados',
    'no_workspace_available': 'No hay espacios de trabajo disponibles. Por favor crea uno.',
    'finished_processing_out_of_sync': "Finalizado el procesamiento de tu solicitud. Si los objetos detectados están desincronizados, por favor haz clic en 'Procesar objetos de imagen' para resincronizar.",
    'processing_taking_long_retry': 'Nuestro sistema está tardando más de lo usual. Reintentando...',
    'not_selected_remove': 'Por favor selecciona un objeto para REMOVER tocando o haciendo clic en la imagen',
    'not_selected_replace': 'Por favor selecciona un objeto para REEMPLAZAR tocando o haciendo clic en la imagen',
    'untitled': 'Sin título',
    'tap_click_object_to_replace': 'Por favor toca o haz clic en un objeto para reemplazar',
    'replacing_selected_object': 'Reemplazando objeto seleccionado',
    'reimagining_your_space': 'Reimaginando tu espacio...',
    'generating_inspiration': 'Generando tu diseño inspirador. Esto generará una nueva imagen, no modificará tu imagen existente. Usa "diseña mi imagen" para modificar tu imagen existente. Por favor espera un momento (8 segundos)...',
    'enhancing_your_image': 'Mejorando tu imagen',
    'thank_you_start_designing': '¡Comienza a diseñar ahora creando un espacio de trabajo!',
    'thank_you': 'Gracias',
    'for_subs': 'por suscribirte!',
    'erasing_selected': 'Borrando objeto seleccionado',
    'select_an_object': 'Por favor selecciona un objeto haciendo clic o tocando la imagen',
    'turn_off': "Apagar",
    'help_alert': "Para obtener los mejores resultados, por favor sea lo más descriptivo posible al proporcionar instrucciones a la Inteligencia Artificial. Utilice la opción de rediseñar para remezclar diseños también.",
    'inspire_me': "Inspírame",
    'or': "O",
    'image_uploaded': "Imagen subida. Intenta usar 'Rediseña Mi Imagen' para modificar tu interior/exterior",
    'default_workspace_name': 'Mis Diseños',
    'Select': 'Seleccionar',
    'Change': 'Cambiar',
    'Please select an item.': 'Por favor, selecciona un elemento.',
    'Space Type': 'Tipo de Espacio',
    'Specific Interior Space': 'Espacio Interior Específico',
    'Specific Exterior Space': 'Espacio Exterior Específico',
    'Style': 'Estilo',
    'Item to Emphasize': 'Elemento a Resaltar',
    'What to Emphasize': 'Qué Resaltar',
    'Write the': 'Escribe el',
    'Color Scheme': 'Esquema de Colores',
    'Budget Constraints': 'Restricciones de Presupuesto',
    'Previous': 'Anterior',
    'Next': 'Siguiente',
    'Redesign': 'Rediseñar',
    'Selected Options Summary': 'Resumen de Opciones Seleccionadas',
    'Space Type:': 'Tipo de Espacio:',
    'Specific Space:': 'Espacio Específico:',
    'Style:': 'Estilo:',
    'Emphasis:': 'Énfasis:',
    'What to Emphasize:': 'Qué Resaltar:',
    'Detail Option:': 'Opción de Detalle:',
    'Color Scheme:': 'Esquema de Colores:',
    'Budget:': 'Presupuesto:',
    'Download': 'Descargar',
    're_use': 'Rehacer',
    'host': 'DecoracionInteligente.com',
    'Download_with_watermark': 'Descargar con marca',
    'invalid_prompt': 'Por favor, ingrese una descripción válida',
    'free_form': 'Quiero describir exactamente lo que quiero (Texto Libre)',
    'use_selection_form': 'Quiero usar el formulario de selección',
};
