import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector } from "react-redux";
import { Auth, I18n } from "aws-amplify";

const ProfileMenu = (props: any) => {
  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("Admin");

  const [name, setName] = useState("");

  useEffect(() => {
    Promise.resolve(Auth.currentUserInfo()).then((userInfo: any) => {
      if (userInfo && userInfo.attributes) {
        setName(userInfo.attributes.name);
      }
    });
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="">{name}</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <a className="dropdown-item hover:cursor-pointer" onClick={async () => Auth.signOut()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{I18n.get("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(ProfileMenu);
